import React from 'react';
import style from './ImageSign.module.scss';
import UnderlinedText from '../UnderlinedText/UnderlinedText';

interface Sign {
	title: string;
	image: string;
	text: string;
}

interface ImageSignProps {
	signs: Array<Sign>;
}

const ImageSign: React.FC<ImageSignProps> = ({ signs }) => {
	const [lastSignSelected, setLastSignSelected] = React.useState(0);

	return (
		<div className={style.container}>
			<div className={style.leftSide}>
				{
					signs.map((sign, index) => (<span key={sign.title} onMouseOver={(): void => setLastSignSelected(index)}>
						{index === lastSignSelected ? <UnderlinedText offset={12} colorGradient={'#EF3A5B 0%, #DF3552 18.38%, #D12F49 45.92%, #C92B43 73.27%, #C72A41 100%'}>{sign.title}</UnderlinedText> : sign.title}
					</span>))
				}
			</div>
			<div className={style.rightSide}>
				<div className={style.imageContainer}>
					<img src={signs[lastSignSelected].image} alt="sign" />
				</div>
				<div className={style.textContainer}>
					<span>
						{signs[lastSignSelected].text}
					</span>
				</div>
			</div>
		</div>
	);
};

export default ImageSign;
