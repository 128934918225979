import React from 'react';
import style from './RoundedButton.module.scss';

interface RoundedButtonProps {
	title: string;
	icon: string;
	action?: () => void;
}

const RoundedButton: React.FC<RoundedButtonProps> = ({ title, icon, action }) => {
	return (
		<div className={style.container} onClick={action}>
			<img src={icon} alt={title} className={style.icon} />
			<span className={style.title}>{title}</span>
		</div>
	);
};

export default RoundedButton;
