import React, { ReactNode } from 'react';

export interface AuthProviderStore {
	isAuth: boolean;
}

export interface AuthUpdateProviderStore {
	// eslint-disable-next-line no-unused-vars
	setIsAuth: (isAuth: boolean) => void;
}

export const AuthContext = React.createContext({} as AuthProviderStore);
export const AuthUpdateContext = React.createContext({} as AuthUpdateProviderStore);

const AuthProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [isAuth, setIsAuth] = React.useState<boolean>(false);

	const store: AuthProviderStore = {
		isAuth,
	};

	const updateStore: AuthUpdateProviderStore = {
		setIsAuth,
	};

	return (
		<AuthContext.Provider value={store}>
			<AuthUpdateContext.Provider value={updateStore}>
				{children}
			</AuthUpdateContext.Provider>
		</AuthContext.Provider>
	);
};

export { AuthProvider };
export const AuthConsumer = AuthContext.Consumer;
