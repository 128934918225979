import { Route, Routes } from 'react-router-dom';
import About from '../About/About';
import ConnectedImage from '../../assets/img/connected.jpg';
import GuardedImage from '../../assets/img/guarded.jpg';
import IsolatedImage from '../../assets/img/isolated.jpg';
import ProactiveImage from '../../assets/img/proactive.jpg';
import React from 'react';
import RestrictedImage from '../../assets/img/restricted.jpg';
import SheIsConnectedSign from '../../assets/img/she_is_connected_sign.png';
import SheIsProactiveSign from '../../assets/img/she_is_proactive_sign.png';
import SheIsStrongSign from '../../assets/img/she_is_strong_sign.png';
import SheIsVulnerableSign from '../../assets/img/she_is_Vulnerable_sign.png';
import StrongImage from '../../assets/img/strong.jpg';
import VulnerableImage from '../../assets/img/vulnerable.jpg';
import Welcome from './Sections/Welcome';
import WoundedImage from '../../assets/img/wounded.jpg';

const Main: React.FC = () => {
	const [isLoading, setIsLoading] = React.useState(true);

	const cacheImages = async (srcArray: string[]) => {
		const promises = srcArray.map(src => {
			return new Promise<void>(((resolve, reject) => {
				const img = new Image();

				img.src = src;
				img.onload = () => resolve();
				img.onerror = () => reject();
			}));
		});

		await Promise.all(promises);

		setIsLoading(false);
	};

	React.useEffect(() => {
		const imgsToPreload = [
			SheIsVulnerableSign,
			SheIsStrongSign,
			SheIsConnectedSign,
			SheIsProactiveSign,
			StrongImage,
			WoundedImage,
			ConnectedImage,
			IsolatedImage,
			VulnerableImage,
			GuardedImage,
			ProactiveImage,
			RestrictedImage,
		];

		cacheImages(imgsToPreload);
	}, []);

	return (
		<>
			{
				isLoading ? (
					<div style={{ position: 'absolute', backgroundColor: '#000', top: 0, bottom: 0, right: 0, left: 0 }} />
				) : (
					<Routes>
						<Route path="/" element={<Welcome />} />
						<Route path="about" element={<About />} />
					</Routes>
				)
			}
		</>
	);
};

export default Main;
