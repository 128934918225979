import React from 'react';

interface ScatteredPollaroidsGalleryProps {
	imgs: string[];
}

const ScatteredPollaroidsGallery: React.FC<ScatteredPollaroidsGalleryProps> = ({ imgs }) => {
	const divRef = React.useRef(null);

	React.useEffect(() => {
		if (divRef) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).initializeScatteredGallery();
		}
	});

	return (
		<div style={{ position: 'relative', height: 700, margin: '150px 0' }}>
			{/* <div id="preload_overlay" /> */}
			<div id="ffka_sc_wrap" ref={divRef}>
				{
					imgs.map((img, index) => (
						<img key={img} src={img} alt={img + index} />
					))
				}
			</div>
		</div>
	);
};

export default ScatteredPollaroidsGallery;
