import BackLink from '../../components/BackLink/BackLink';
import Balance from '../../assets/img/deepMetaphors/balance_img.jpg';
import Connection from '../../assets/img/deepMetaphors/connection_img.jpg';
import Container from '../../assets/img/deepMetaphors/container_img.jpg';
import Contrast from '../../assets/img/deepMetaphors/contrast_img.jpg';
import Control from '../../assets/img/deepMetaphors/control_img.jpg';
import Fight from '../../assets/img/deepMetaphors/fight_img.jpg';
import Force from '../../assets/img/deepMetaphors/force_img.jpg';
import Journey from '../../assets/img/deepMetaphors/journey_img.jpg';
import Menu from '../../components/Menu/Menu';
import Motion from '../../assets/img/deepMetaphors/motion_img.jpg';
import Nature from '../../assets/img/deepMetaphors/nature_img.jpg';
import React from 'react';
import Resource from '../../assets/img/deepMetaphors/resource_img.jpg';
import Sacred from '../../assets/img/deepMetaphors/sacred_img.jpg';
import ScrollDown from '../../assets/img/scroll_down.svg';
import style from './About.module.scss';
import System from '../../assets/img/deepMetaphors/system_img.jpg';
import Time from '../../assets/img/deepMetaphors/time_img.jpg';
import Transformation from '../../assets/img/deepMetaphors/transformation_img.jpg';
import UnderlinedText from '../../components/UnderlinedText/UnderlinedText';
import WomenGroup from '../../assets/img/group_women.jpg';

const About: React.FC = () => {
	React.useEffect(() => {
		document.body.scrollTo(0, 0);
	}, []);

	const scrollTo = (elementId: string): void => {
		const element = document.querySelector('#' + elementId);
		const eleRect = element?.getBoundingClientRect();
		const bodyRect = document.body.getBoundingClientRect();

		document.body.scrollTo(0, eleRect?.top || 0 - bodyRect.top);
	};

	return (
		<div className={style.container}>
			<div className={style.firstSection}>
				<div className={style.aboutStudy}>
					<Menu isDarkMode={true} />
					<div className={style.title}>
						About the Study
					</div>
					<div className={style.scrollDownImageContainer} onClick={() => scrollTo('universalTruths')}>
						<img src={ScrollDown} alt="scroll down" />
					</div>
				</div>
				<div id="universalTruths" className={style.universalTruths}>
					<div className={style.title}>
						Deep Metaphors are Universal Truths
					</div>
					<p className={style.text}><span style={{ color: '#B1866D' }}>What are they?</span><br />Deep Metaphors are universal unconscious mental frames that emerge from embodied cognition.</p>
					<p className={style.text}><span style={{ color: '#B1866D' }}>How do we uncover them?</span><br />By thoroughly analyzing patterns in language and imagery.</p>
					<p className={style.text}><span style={{ color: '#B1866D' }}>Why do they matter?</span><br />A message that is is on frame is more likely to reach and engage than one that is off-frame.</p>
				</div>
				<div className={style.deepMetaphors}>
					<div className={style.title}><UnderlinedText colorGradient={'#FFEFBE 0%, #FFEFBE 100%'}>Discovering the correct Deep Metaphors</UnderlinedText> is necessary for effective positioning.</div>
					<div className={style.imagesContainer}>
						<div className={style.imagesRow}>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Motion} alt={'motion'} />
								</div>
								<span>motion</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Balance} alt={'balance'} />
								</div>
								<span>balance</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Time} alt={'time'} />
								</div>
								<span>time</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Connection} alt={'connection'} />
								</div>
								<span>connection</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={System} alt={'system'} />
								</div>
								<span>system</span>
							</div>
						</div>
						<div className={style.imagesRow}>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Journey} alt={'journey'} />
								</div>
								<span>journey</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Resource} alt={'resource'} />
								</div>
								<span>resource</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Nature} alt={'nature'} />
								</div>
								<span>nature</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Container} alt={'container'} />
								</div>
								<span>container</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Force} alt={'force'} />
								</div>
								<span>force</span>
							</div>
						</div>
						<div className={style.imagesRow}>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Control} alt={'control'} />
								</div>
								<span>control</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Transformation} alt={'transformation'} />
								</div>
								<span>transformation</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Fight} alt={'fight/flight'} />
								</div>
								<span>fight/flight</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Sacred} alt={'sacred/profane'} />
								</div>
								<span>sacred/profane</span>
							</div>
							<div className={style.imageContainer}>
								<div className={style.imageWrapper}>
									<img src={Contrast} alt={'contrast/paradox'} />
								</div>
								<span>contrast/paradox</span>
							</div>
						</div>
					</div>
					<div className={style.goBackLink}>
						<BackLink text={'BACK TO MAIN JOURNEY'} navigateTo={'/'} />
					</div>
				</div>
				<div className={style.whatWeKnow}>
					<div className={style.title}>What we know from previous research</div>
					<div className={style.columns}>
						<div className={style.column}>
							<div className={style.title}>The Millennial Mom is</div>
							<div className={style.points}>
								<ul>
									<li>
										Significant UF emotional impact
									</li>
									<li>
										Highly receptive to hormonal treatment
									</li>
									<li>
										Highly receptive to TPP
									</li>
									<li>
										Highly likely to take action; proactive
									</li>
									<li>
										Look for treatments on their own
									</li>
									<li>
										High frequency of speaking with doctor
									</li>
									<li>
										Highest discretionary income
									</li>
								</ul>
							</div>
						</div>
						<div className={style.column}>
							<div className={style.title}>THE MATURE CRUSADER IS</div>
							<div className={style.points}>
								<ul>
									<li>
										Highly receptive to the TPP next 3 mos.
									</li>
									<li>
										Speak with doctor 3.7 times/yr.
									</li>
									<li>
										Proactive in their own treatment
									</li>
									<li>
										Comfortable using hormonal therapy
									</li>
									<li>
										Not worried about future fertility
									</li>
									<li>
										Emotionally and socially impacted by their fibroids
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className={style.weSpoke}>
					<div className={style.weSpokeInternal}>
						<div className={style.title}>We spoke to both Mature Crusader and Millennial Moms</div>
						<div className={style.content}>
							<div className={style.imageContainer}>
								<img src={WomenGroup} alt={'women group'} />
							</div>
							<div className={style.text}>
								<p className={style.title}>OUR SAMPLE</p>
								<p>Mobile Ethnography Research n=30<br />n=15 Millennial Mom, ≥50% AA women<br />n=15 Mature Warriors, ≥50% AA women</p>
								<p>ZMET IDI Research: Hand Selected from MobE n=24<br />n=12 Millennial Mom ≥50% AA women<br />n=12 Mature Warriors, ≥50% AA women</p>
								<p>ZMET Dinner Table Talks Research n=2 small groups<br />n=1 AA Millennial Mom + friend/family<br />n=2 AA Mature Crusader + friend/family</p>
							</div>
						</div>
					</div>
				</div>
				<div className={style.credits}>
					<div className={style.title}>Credits</div>
					<div className={style.text}>
						To learn more, visit <a href="https://www.olsonzaltman.com/" target="_blank" rel="noreferrer">Olson Zaltman&apos;s website</a>
					</div>
					<div className={style.goBackLink}>
						<BackLink text={'BACK TO MAIN JOURNEY'} navigateTo={'/'} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default About;
