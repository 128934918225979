import Close from '../../assets/img/close.svg';
import MenuLogo from '../../assets/img/menu.svg';
import MyovantLogo from '../../assets/img/myovant_logo.png';
import OZLogo from '../../assets/img/oz_logo.svg';
import PfizerLogo from '../../assets/img/pfizer_logo.png';
import React from 'react';
import style from './Menu.module.scss';
import { useNavigate } from 'react-router-dom';

interface MenuProps {
	isDarkMode?: boolean;
	hideOptions?: boolean;
}

const Menu: React.FC<MenuProps> = ({ isDarkMode, hideOptions }) => {
	const containerClass = isDarkMode ? style.container + ' ' + style.withDarkFilter : style.container;
	const [showMenu, setShowMenu] = React.useState(false);
	const navigate = useNavigate();

	const navigateTo = (path: string, param?: string) => {
		let fullPath = path;

		if (param) {
			fullPath = fullPath + '?section=' + param;
		}

		setShowMenu(false);
		navigate(fullPath);
	};

	return (
		<div className={containerClass}>
			<div className={style.containerLeftSide}>
				<div className={style.ozLogoContainer}>
					<img src={OZLogo} alt={'OZ Logo'} />
				</div>
			</div>
			<div className={style.containerRightSide}>
				<div className={style.pfizerLogoContainer}>
					<img src={PfizerLogo} alt={'Pfizer Logo'} />
				</div>
				<div className={style.myovantLogoContainer}>
					<img src={MyovantLogo} alt={'Myovant Logo'} />
				</div>
				{!hideOptions && <div className={style.menuLogoContainer} onClick={() => setShowMenu(true)}>
					<img src={MenuLogo} alt={'Menu'} />
				</div>}
			</div>
			{showMenu && (
				<div className={style.menuContainer}>
					<div className={style.title}>Illuminating the Full Picture of Fibroids</div>
					<div className={style.content}>
						<div className={style.option} onClick={() => navigateTo('/', 'introduction')}>Introduction</div>
						<div className={style.option} onClick={() => navigateTo('/', 'chapter1')}>Chapter 1: Who she is (outside of UF)</div>
						<div className={style.option} onClick={() => navigateTo('/', 'chapter2')}>Chapter 2: Disease Impact</div>
						<div className={style.option} onClick={() => navigateTo('/', 'chapter3')}>Chapter 3: Opportunity Space</div>
						<div className={style.option} onClick={() => navigateTo('/', 'patientsGallery')}>The Gallery of Our Target UF Patients</div>
						<div className={style.option} onClick={() => navigateTo('/about')}>About the Study</div>
					</div>
					<div className={style.close} onClick={() => setShowMenu(false)}><img src={Close} alt={'close'} /></div>
				</div>
			)}
		</div>
	);
};

export default Menu;
