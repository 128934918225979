import Plus from '../../assets/img/plus.svg';
import React from 'react';
import style from './Signature.module.scss';
import { useNavigate } from 'react-router-dom';

interface SignatureProps {
	text: string;
	fontSize?: number;
	quoteWidth?: number;
	quoteHeight?: number;
	lineHeight?: number;
	externalStyle?: React.CSSProperties;
	navigateTo?: string;
	openModal?: () => void;
}

const Signature: React.FC<SignatureProps> = ({ text, fontSize, lineHeight, externalStyle, navigateTo = '/', openModal }) => {
	const navigate = useNavigate();
	const size = fontSize || 16;

	return (
		<div className={style.container} style={{ textAlign: 'right', ...externalStyle }} onClick={openModal || (() => navigate(navigateTo))}>
			<div className={style.plusContainer}>
				<img src={Plus} alt="plus" style={{ width: size * 1.625 }} />
			</div>
			<span className={style.signature} style={{ fontSize: size, lineHeight: `${lineHeight || 20}px` }}>
				{text}
			</span>
		</div>
	);
};

export default Signature;
