import BackArrow from '../../assets/img/back_arrow.svg';
import React from 'react';
import style from './BackLink.module.scss';
import { useNavigate } from 'react-router-dom';

interface BackLinkProps {
	text: string;
	navigateTo: string;
}

const BackLink: React.FC<BackLinkProps> = ({ text, navigateTo }) => {
	const navigate = useNavigate();

	return (
		<div className={style.container} onClick={() => navigate(navigateTo)}>
			<img src={BackArrow} alt={'back arrow'} />
			<span>{text}</span>
		</div>
	);
};

export default BackLink;
