import ChapterNumber from '../ChapterNumber/ChapterNumber';
import ChapterTitle from '../ChapterTitle/ChapterTitle';
import React from 'react';
import style from './Chapter.module.scss';

interface ChapterProps {
	chapterNumber: string;
	chapterTitle: string;
	chapterBackgroundImage?: string;
}

const Chapter: React.FC<ChapterProps> = ({ chapterNumber, chapterTitle, chapterBackgroundImage }) => {
	return (
		<div className={style.container}>
			<ChapterNumber text={chapterNumber} />
			<div className={style.middleSection} style={{ backgroundImage: `url(${chapterBackgroundImage})` }}>
				<ChapterTitle text={chapterTitle} />
			</div>
		</div>
	);
};

export default Chapter;
