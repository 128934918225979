import React from 'react';
import style from './UnderlinedText.module.scss';

interface UnderlinedTextProps {
	colorGradient?: string;
	offset?: number;
}

const UnderlinedText: React.FC<UnderlinedTextProps> = ({ colorGradient, children, offset }) => {
	const componentRef = React.useRef<HTMLSpanElement | null>(null);
	const underlineColorGradient = colorGradient || '#F9AA78 0%, #FFD36B 57.2%, #FBB875 83.22%, #F9AA78 100%';

	React.useEffect(() => {
		if (componentRef) {
			(() => componentRef.current?.style.setProperty('--underline-offset-y', `-${offset || 0}px`))();
		}
	});

	return (
		<span
			className={style.underline}
			style={{
				backgroundImage: `linear-gradient(90deg,${underlineColorGradient})`,
			}}
			ref={componentRef}
		>
			{children}
		</span>
	);
};

export default UnderlinedText;
