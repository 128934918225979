import React from 'react';

export default function useOnScreen(ref: any): boolean {
	const [isIntersecting, setIntersecting] = React.useState(false);

	const observer = new IntersectionObserver(
		([entry]) => setIntersecting(entry.isIntersecting)
	);

	React.useEffect(() => {
		if (ref && ref.current) {
			observer.observe(ref.current);
		}

		// Remove the observer as soon as the component is unmounted
		return () => { observer.disconnect(); };
	}, []);

	return isIntersecting;
}
