import { AuthContext } from './contexts/AuthContext';
import Login from './pages/Login/Login';
import Main from './pages/Main/Main';
import React from 'react';

const App: React.FC = () => {
	const { isAuth } = React.useContext(AuthContext);

	return (
		<div>
			{isAuth ? <Main /> : <Login />}
		</div>
	);
};

export default App;
