import Chapter from '../../../components/Chapter/Chapter';
import ChapterImage01 from '../../../assets/img/chapter_number_1.svg';
import ChapterImage02 from '../../../assets/img/chapter_number_2.svg';
import ChapterImage03 from '../../../assets/img/chapter_number_3.svg';
import CloseIcon from '../../../assets/img/close_participant.svg';
import ConnectedImage from '../../../assets/img/connected.jpg';
import GuardedImage from '../../../assets/img/guarded.jpg';
import ImageCarousel from '../../../components/ImageCarousel/ImageCarousel';
import ImageGalleryIcon from '../../../assets/img/image_gallery_icon.png';
import ImageSign from '../../../components/ImageSign/ImageSign';
import InControl from '../../../assets/video/in_control.mp4';
import IsolatedImage from '../../../assets/img/isolated.jpg';
import LetterFuture1 from '../../../assets/img/letters/future_1.jpg';
import LetterFuture3 from '../../../assets/img/letters/future_3.jpg';
import LetterPast1 from '../../../assets/img/letters/past_1.jpg';
import LetterPast3 from '../../../assets/img/letters/past_3.jpg';
import Menu from '../../../components/Menu/Menu';
import Modal from 'react-modal';
import NextIcon from '../../../assets/img/next.svg';
import OpenText from '../../../components/OpenText/OpenText';
import OpportunityToBeFree from '../../../assets/video/opportunity_to_be_free.mp4';
import Participants from './Participants';
import Phrase from '../../../components/Phrase/Phrase';
import PollaroidChaos from '../../../assets/img/pollaroids/chaos.jpg';
import PollaroidDrowning from '../../../assets/img/pollaroids/drowning.jpg';
import PollaroidEmotionallyFearful from '../../../assets/img/pollaroids/emotionally_fearful.jpg';
import PollaroidGremlinDraining from '../../../assets/img/pollaroids/gremlin_draining.jpg';
import PollaroidSpinningOutOfControl from '../../../assets/img/pollaroids/spinning_out_of_control.jpg';
import PollaroidStorm from '../../../assets/img/pollaroids/storm.jpg';
import PollaroidTsunami from '../../../assets/img/pollaroids/tsunami.jpg';
import PollaroidWaveCrashing from '../../../assets/img/pollaroids/wave_crashing.jpg';
import PreviousIcon from '../../../assets/img/previous.svg';
import ProactiveImage from '../../../assets/img/proactive.jpg';
import React from 'react';
import RestrictedImage from '../../../assets/img/restricted.jpg';
import RoundedButton from '../../../components/RoundedButton/RoundedButton';
import ScatteredPollaroidsGallery from '../../../components/ScatteredPollaroidsGallery/ScatteredPollaroidsGallery';
import ScrollDown from '../../../assets/img/scroll_down.svg';
import SheIsConnectedSign from '../../../assets/img/she_is_connected_sign.png';
import SheIsNotHeard from '../../../assets/video/she_is_not_heard.mp4';
import SheIsProactiveSign from '../../../assets/img/she_is_proactive_sign.png';
import SheIsStrongSign from '../../../assets/img/she_is_strong_sign.png';
import SheIsVulnerableSign from '../../../assets/img/she_is_Vulnerable_sign.png';
import Signature from '../../../components/Signature/Signature';
import Sketch1 from '../../../assets/img/sketchs/sketch_1.jpg';
import Sketch2 from '../../../assets/img/sketchs/sketch_2.jpg';
import Sketch3 from '../../../assets/img/sketchs/sketch_3.jpg';
import Sticky from 'react-sticky-el';
import StrongImage from '../../../assets/img/strong.jpg';
import style from './Welcome.module.scss';
import TurnCard from '../../../components/TurnCard/TurnCard';
import UnderlinedText from '../../../components/UnderlinedText/UnderlinedText';
import { useLocation } from 'react-router-dom';
import useOnScreen from '../../../hooks/useVisible';
import VulnerableImage from '../../../assets/img/vulnerable.jpg';
import WoundedImage from '../../../assets/img/wounded.jpg';

const AboutTheDiseaseData = <React.Fragment>
	<p><UnderlinedText>Uterine fibroids</UnderlinedText> are noncancerous tumors that develop in or on the muscular walls of the uterus and are among the most common reproductive tract tumors in women. In addition to an individual&apos;s genetic predisposition, estrogens are well known to play an important role in the regulation of fibroid growth.</p>
	<p>Although uterine fibroids are benign tumors, they can cause debilitating symptoms such as heavy menstrual bleeding (frequently resulting in anemia and fatigue), pain (including painful periods, abdominal pain, painful intercourse, backache), increased abdominal girth and bloating, urinary frequency or retention, constipation, pregnancy loss, and, in some cases, infertility.</p>
	<p>These symptoms can also lead to loss of productivity at work, limitations in normal activities of daily living, and social embarrassment.</p>
	<p>An estimated five million women in the U.S. suffer from symptoms of uterine fibroids, and an estimated three million women are inadequately treated by current medical therapy and require further treatment.</p>
</React.Fragment>;
const AboutTheStudyData = <React.Fragment>
	<p>We did a deep dive in order to paint the full picture of the UF patient in vivid color. We entered into the lives of UF women to discover:</p>
	<p><span style={{ color: '#FFF0BE' }}>The Nuances:</span> The easily-missed details that yield profound insights.</p>
	<p><span style={{ color: '#FFF0BE' }}>The Emotional Landscape:</span> The deep emotional drivers and barriers to create an effective emotional positioning.</p>
	<p><span style={{ color: '#FFF0BE' }}>The Conscious and Unconscious:</span> The deep human truths that live in the unconscious mind and drive decision making.</p>
	<Signature
		text={'More information'}
		externalStyle={{ marginTop: 60 }}
		navigateTo={'about'}
	/>
</React.Fragment>;

const customStyles = {
	content: {
		backgroundColor: 'rgba(0,0,0,1)',
		border: 0,
	},
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.5)',
	},
};

const letters = [
	{
		image: LetterPast1,
	},
	{
		image: LetterPast3,
	},
	{
		image: LetterFuture1,
	},
	{
		image: LetterFuture3,
	},
];

const sketchs = [
	{
		image: Sketch1,
	},
	{
		image: Sketch2,
	},
	{
		image: Sketch3,
	},
];

Modal.setAppElement('#root');

const Welcome: React.FC = () => {
	const [emotionalModalIsOpen, setEmotionalModalIsOpen] = React.useState(false);
	const [drawingsModalIsOpen, setDrawingsModalIsOpen] = React.useState(false);
	const [participantsModalIsOpen, setParticipantsModalIsOpen] = React.useState(false);
	const [participantToShow, setParticipantToShow] = React.useState(Participants[0]);
	const { search } = useLocation();
	const video3Ref = React.useRef<HTMLVideoElement>();
	const isVideo3RefVisible = useOnScreen(video3Ref);
	const video2Ref = React.useRef<HTMLVideoElement>();
	const isVideo2RefVisible = useOnScreen(video2Ref);
	const video1Ref = React.useRef<HTMLVideoElement>();
	const isVideo1RefVisible = useOnScreen(video1Ref);

	const openParticipantsModal = (participantIndex: number) => {
		let participantIndexFinal = participantIndex;

		if (participantIndexFinal < 0) {
			participantIndexFinal = Participants.length - 1;
		} else if (participantIndexFinal >= Participants.length) {
			participantIndexFinal = 0;
		}

		setParticipantToShow(Participants[participantIndexFinal]);
		setParticipantsModalIsOpen(true);
	};

	const closeParticipantsModal = () => {
		setParticipantsModalIsOpen(false);
	};

	const openEmotionalModal = () => {
		setEmotionalModalIsOpen(true);
	};

	const closeEmotionalModal = () => {
		setEmotionalModalIsOpen(false);
	};

	const openDrawingsModal = () => {
		setDrawingsModalIsOpen(true);
	};

	const closeDrawingsModal = () => {
		setDrawingsModalIsOpen(false);
	};

	React.useEffect(() => {
		document.body.scrollTo(0, 0);
	}, []);

	const scrollTo = React.useCallback((elementId: string): void => {
		const element = document.getElementById(elementId);
		const eleRect = element?.getBoundingClientRect();
		const bodyRect = document.body.getBoundingClientRect();

		document.body.scrollTo(0, eleRect?.top || 0 - bodyRect.top);
	}, []);

	React.useEffect(() => {
		const params = new URLSearchParams(search);
		const section = params.get('section');

		if (section) {
			scrollTo(section);
		}
	}, [scrollTo, search]);

	React.useEffect(() => {
		if (video3Ref && video3Ref.current) {
			if (isVideo3RefVisible) {
				video3Ref.current.play();
			} else {
				video3Ref.current.pause();
			}
		}
	}, [isVideo3RefVisible]);

	React.useEffect(() => {
		if (video2Ref && video2Ref.current) {
			if (isVideo2RefVisible) {
				video2Ref.current.play();
			} else {
				video2Ref.current.pause();
			}
		}
	}, [isVideo2RefVisible]);

	React.useEffect(() => {
		if (video1Ref && video1Ref.current) {
			if (isVideo1RefVisible) {
				video1Ref.current.play();
			} else {
				video1Ref.current.pause();
			}
		}
	}, [isVideo1RefVisible]);

	return (
		<div className={style.container}>
			<div className={style.firstSection}>
				<div className={style.illuminatingFullPicture}>
					<Menu />
					<span className={style.title}>
						Illuminating the Full Picture of Fibroids
					</span>
					<span className={style.subtitle}>An unconscious, emotional deep dive</span>
					<span className={style.date}>April 2021</span>
					<div className={style.scrollDownImageContainer} onClick={() => scrollTo('introduction')}>
						<img src={ScrollDown} alt="scroll down" />
					</div>
				</div>
				<div className={style.introSection}>
					<div id="introduction" className={style.introduction}>
						Introduction
					</div>
					<OpenText
						title="About the Disease"
						data={AboutTheDiseaseData}
					/>
					<OpenText
						title="About the Study"
						data={AboutTheStudyData}
					/>
				</div>
				<div className={style.phrase}>
					<Phrase
						text={'When I’m balanced and prepared, I can live to fight another day. There’s so many other things in the world. And all my life I’ve had to fight'}
						externalStyle={{ width: 1100 }}
					/>
					<Signature
						text={'Akia W, Target UF Patient'}
						externalStyle={{ marginTop: 104 }}
						openModal={() => openParticipantsModal(9)}
					/>
				</div>
				<div id="chapter1" className={style.chapter1}>
					<Chapter
						chapterNumber={'chapter 01'}
						chapterTitle={'Who she is (outside of UF)'}
						chapterBackgroundImage={ChapterImage01}
					/>
				</div>
				<div className={style.sheIsStrong}>
					<div className={style.sheIsStrongInternalContainer}>
						<Signature
							text={'This image was selected by one of our target UF patients: jennifer'}
							externalStyle={{ position: 'absolute', bottom: 600, width: 385, left: 0, textAlign: 'left' }}
							openModal={() => openParticipantsModal(5)}
						/>
					</div>
				</div>
				<div className={style.sheFeels}>
					<p><UnderlinedText>Most days she feels free to be her best self</UnderlinedText> - her best self is defined/determined by her sense of control over her life and everything in it.</p>
					<p>When she has a grasp on her life and everything in it and is free to be her best self, she is:</p>
				</div>
				<ImageSign
					signs={[
						{
							title: 'Strong',
							image: SheIsStrongSign,
							text: '“I am a strong person mentally, and I can\'t let anything get in my way.” - Stephanie W',
						},
						{
							title: 'Connected',
							image: SheIsConnectedSign,
							text: '“My friends and family make my life feel full... At the end of the day, I want to make a difference in other’s lives.” - Rayna T',
						},
						{
							title: 'Vulnerable',
							image: SheIsVulnerableSign,
							text: '“Any good relationship has to have vulnerability. You have to be honest and open and allow others to be there for you” - Naydalis P',
						},
						{
							title: 'Proactive',
							image: SheIsProactiveSign,
							text: '“I make sure that my body is strong and healthy and able to do things… I push through no matter what.” - Rachel K',
						},
					]}
				/>
				<div className={style.sheApproachesContainer}>
					<div className={style.sheApproachesContainerInternalContainer}>
						<div className={style.sheApproaches}>
							<p>Similar to how she approaches life, control is also pertinent to how she approaches her relationship with her uterus.</p>
							<p>She has a strong appreciation and <UnderlinedText>connection to womanhood.</UnderlinedText></p>
						</div>
						<div className={style.sheApproachesSignature}>
							<Signature
								text={'This image was selected by one of our target UF patients: jennifer'}
								externalStyle={{ position: 'absolute', bottom: 200, width: 385, left: 0, textAlign: 'left' }}
								openModal={() => openParticipantsModal(5)}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={style.secondSection}>
				<div className={style.firstVideo}>
					<div className={style.sheApproachesVideoContainer}>
						<div className={style.sheApproachesVideo}>
							<Sticky scrollElement={'body'} stickyStyle={{ paddingTop: 20 }} boundaryElement={'.' + style.sheApproachesVideo}>
								<video width="100%" controls ref={video1Ref as unknown as (React.LegacyRef<HTMLVideoElement> | undefined)}>
									<source src={InControl} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</Sticky>
						</div>
						<div className={style.cloudsContainer}>
							<div className={style.clouds}>
								<div className={style.phrase2}>
									<Phrase
										text={'It feels like a storm that’s brewing inside my body. I’m right in the middle of the lightning, surrounded by the chaos, the fear'}
										externalStyle={{ width: 1100 }}
									/>
									<Signature
										text={'Brenna P, Target UF Patient'}
										externalStyle={{ marginTop: 104 }}
										openModal={() => openParticipantsModal(16)}
									/>
								</div>
								<div id="chapter2" className={style.chapterTwo}>
									<Chapter
										chapterNumber={'chapter 02'}
										chapterTitle={'Desease Impact'}
										chapterBackgroundImage={ChapterImage02}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={style.sheStrugglesContainer}>
					<div className={style.sheStrugglesStickyContainer}>
						<Sticky scrollElement={'body'} boundaryElement={'.' + style.sheStrugglesContainer}>
							<div className={style.sheStruggles}>
								<p>BUT when her uterine fibroid symptoms kick in, she has to fight to maintain control over her life and everything in it.</p>
								<p><UnderlinedText colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>She struggles monthly to stay afloat in the dangerous waters of UF</UnderlinedText> - enjoying the calm, liberating waters before the full force of the waves (her period) come crashing down on her.</p>
							</div>
						</Sticky>
					</div>
					<div className={style.strugglesContainer}>
						<div className={style.struggles} />
					</div>
				</div>
				<div className={style.galleryContainer}>
					<ScatteredPollaroidsGallery
						imgs={[PollaroidChaos, PollaroidDrowning, PollaroidEmotionallyFearful, PollaroidGremlinDraining, PollaroidSpinningOutOfControl, PollaroidStorm, PollaroidTsunami, PollaroidWaveCrashing]}
					/>
				</div>
				<div className={style.bellowGallery}>
					<div className={style.text} onClick={() => scrollTo('patientsGallery')}>These images were selected by our target UF patients. For further details, you can visit our <b>PATIENT GALLERY</b>.</div>
				</div>
				<div className={style.emotionalJourneyContainer}>
					<div className={style.emotionalJourneyContainerInternal}>
						<div className={style.phrase3}>
							<Phrase
								text={'You’ll have days where you feel like you’re on top of the world and days that you’ll want the world to swallow you up'}
								externalStyle={{ width: 1000 }}
							/>
							<Signature
								text={'Latrice H, Target UF Patient'}
								externalStyle={{ marginTop: 104 }}
								openModal={() => openParticipantsModal(10)}
							/>
						</div>
						<div className={style.emotionalJourneyPhraseContainer}>
							<p><UnderlinedText offset={15} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>Emotional Journey:</UnderlinedText> What’s it actually like for her living with UF each month?</p>
							<div className={style.emotionalButtonContainer}>
								<RoundedButton
									title={'Read their letters to self'}
									icon={ImageGalleryIcon}
									action={openEmotionalModal}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className={style.menstruation}>
					<div className={style.imageContainer + ' ' + style.before} />
					<div className={style.textContainer}>
						<span className={style.title}><UnderlinedText offset={15} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>Before</UnderlinedText><br /> Menstruation</span>
						<div className={style.columns}>
							<div className={style.column}>
								<div className={style.columnText}>“I am at peace”</div>
								<div className={style.columnText}>“I can live my life without disruption”</div>
								<div className={style.columnText}>“I feel like I can breathe again”</div>
							</div>
						</div>
						<div className={style.songsTitle}>Listen to the songs that represent their feelings</div>
						<div className={style.songs}>
							<div className={style.song}>
								<iframe src="https://open.spotify.com/embed/track/3qOfAUyqp8XIKFPNM5GDYJ?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className={style.menstruation}>
					<div className={style.imageContainer + ' ' + style.during} />
					<div className={style.textContainer}>
						<span className={style.title}><UnderlinedText offset={15} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>During</UnderlinedText><br /> Menstruation</span>
						<div className={style.columns}>
							<div className={style.column}>
								<div className={style.columnTitle}>Millennial Mom</div>
								<div className={style.columnText}>“Chaotic feeling”</div>
								<div className={style.columnText}>“I feel like I’m juggling”</div>
								<div className={style.columnText}>“Whirlwind of craziness”</div>
							</div>
							<div className={style.column}>
								<div className={style.columnTitle}>Mature Crusader</div>
								<div className={style.columnText}>“It is defeating”</div>
								<div className={style.columnText}>“Draining myself dry”</div>
								<div className={style.columnText}>“Drains your life force”</div>
							</div>
						</div>
						<div className={style.buttonContainer}>
							<RoundedButton
								title={'View the patients’ drawings of uf'}
								icon={ImageGalleryIcon}
								action={openDrawingsModal}
							/>
						</div>
						<div className={style.songsTitle}>Listen to the songs that represent their feelings</div>
						<div className={style.songs}>
							<div className={style.song}>
								<iframe src="https://open.spotify.com/embed/track/09g1lcXe9E2NSzLvHSGCfJ?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
							</div>
							<div className={style.song}>
								<iframe src="https://open.spotify.com/embed/track/3UrDYCWfY6fTsiA63NNL27?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className={style.menstruation}>
					<div className={style.imageContainer + ' ' + style.after} />
					<div className={style.textContainer}>
						<span className={style.title}><UnderlinedText offset={15} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>After</UnderlinedText><br /> Menstruation</span>
						<div className={style.columns}>
							<div className={style.column}>
								<div className={style.columnText}>“I have survived a battle” </div>
								<div className={style.columnText}>“It gives me a sense of having made it and survived one more tme”</div>
								<div className={style.columnText}>“The winner takes it all. It’s over and I am the winner”</div>
							</div>
						</div>
						<div className={style.songsTitle}>Listen to the songs that represent their feelings</div>
						<div className={style.songs}>
							<div className={style.song}>
								<iframe src="https://open.spotify.com/embed/album/72cOowqWvPkFD6vyXu1Bwp?utm_source=generator&theme=0" width="100%" height="80" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
							</div>
						</div>
					</div>
				</div>
				<div className={style.livingInParadoxContainer}>
					<div className={style.livingInParadoxContainerInternal}>
						<div className={style.titleAndDescriptionContainer}>
							<div className={style.title}>Living in <UnderlinedText offset={15} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>paradox</UnderlinedText></div>
							<div className={style.description}>Her experience with UF is defined by its contrasting truths.</div>
						</div>
						<div className={style.phrase4}>
							<Phrase
								text={'It’s a ‘Quiet Storm.’ The outside looks normal. Nobody knows. So, you’re having this whole storm and tsunami inside of you, but to the outside, you look like you'}
								externalStyle={{ width: 950 }}
							/>
							<Signature
								text={'Leanne S, Target UF Patient'}
								externalStyle={{ marginTop: 80 }}
								openModal={() => openParticipantsModal(1)}
							/>
						</div>
					</div>
				</div>
				<div className={style.sheHasLearnedContainer}>
					<div className={style.sheHasLearned}>
						<div className={style.text}><UnderlinedText offset={2} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>She has learned to put on a strong face</UnderlinedText> to the world while inside, her mind and body are drowning. Because of UF, she is:</div>
					</div>
				</div>
				<div className={style.turnCardContainer}>
					<TurnCard
						middleWord={'yet'}
						leftWord={'Strong'}
						rightWord={'Wounded'}
						leftText={'“I\'m strong, I don’t let it tear me down. I’m like a lion up on top of a hill, with a whole bunch of lions under him. So I\'m the strong one. I\'m on top of all of them.”'}
						rightText={'“Someone in the war. They are strong and tough but they are battled and wounded. It can take a lot of pain and it’s there to help carry others but it still gets tired and weary.”'}
						leftColorGradient={'270deg, #EF3A5B 0%, #EF3A5B 0.05%, #F05157 14.96%, #F15D54 29.77%, #F16153 44.23%, #F3765C 57.61%, #F79B70 86.02%, #F9AA78 100%'}
						rightColorGradient={'90deg, #071D27 0%, #07273C 52.77%, #3D6571 100%'}
						leftImage={StrongImage}
						rightImage={WoundedImage}
					/>
				</div>
				<div className={style.turnCardContainer}>
					<TurnCard
						middleWord={'yet'}
						leftWord={'Connected'}
						rightWord={'Isolated'}
						leftText={'“It’s helpful to have somebody that’s willing to be honest and share their story with me... I share things just to try and help people”'}
						rightText={'“I feel like I\'m secluded inside of a dark room, in pain, nobody there to talk to, nothing to help me. There\'s nobody else that understands what I\'m going through.”'}
						leftColorGradient={'270deg, #EF3A5B 0%, #EF3A5B 0.05%, #F05157 14.96%, #F15D54 29.77%, #F16153 44.23%, #F3765C 57.61%, #F79B70 86.02%, #F9AA78 100%'}
						rightColorGradient={'90deg, #071D27 0%, #07273C 52.77%, #3D6571 100%'}
						leftImage={ConnectedImage}
						rightImage={IsolatedImage}
					/>
				</div>
				<div className={style.turnCardContainer}>
					<TurnCard
						middleWord={'yet'}
						leftWord={'Vulnerable'}
						rightWord={'Guarded'}
						leftText={'“With me just really being vulnerable and honest with my doctor it’s opened this door and it’s given me hope that there’s the light in the future.”'}
						rightText={'“I do a very good job at hiding... I feel like I can’t allow others to see my weaknesses. I want others to treat me normally.”'}
						leftColorGradient={'270deg, #EF3A5B 0%, #EF3A5B 0.05%, #F05157 14.96%, #F15D54 29.77%, #F16153 44.23%, #F3765C 57.61%, #F79B70 86.02%, #F9AA78 100%'}
						rightColorGradient={'90deg, #071D27 0%, #07273C 52.77%, #3D6571 100%'}
						leftImage={VulnerableImage}
						rightImage={GuardedImage}
					/>
				</div>
				<div className={style.turnCardContainer}>
					<TurnCard
						middleWord={'yet'}
						leftWord={'Proactive'}
						rightWord={'Restricted'}
						leftText={'“I take charge of my situation by maintaining my health, staying active, taking pain medicine before severe pain, and by trying to maintain a good mindset at the peak of my discomfort. But it’s very hard to maintain these things when you are actually in the moment.”'}
						rightText={'“I’m trapped in a room...I don\'t imagine ever actually making it out of the room, but sitting on the floor exhausted feeling defeated.”'}
						leftColorGradient={'270deg, #EF3A5B 0%, #EF3A5B 0.05%, #F05157 14.96%, #F15D54 29.77%, #F16153 44.23%, #F3765C 57.61%, #F79B70 86.02%, #F9AA78 100%'}
						rightColorGradient={'90deg, #071D27 0%, #07273C 52.77%, #3D6571 100%'}
						leftImage={ProactiveImage}
						rightImage={RestrictedImage}
					/>
				</div>
				<div className={style.sheIsNotHeardContainer}>
					<div className={style.sheIsNotHeardContainerInternal}>
						<div className={style.text}>She often forgets just how much this constant monthly turmoil has actually taken from her – <UnderlinedText offset={0} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>impacting her relationships, her work, her plans for the future</UnderlinedText> - but when she has a moment of pause and reflects/thinks about it, she is frustrated, realizing this isn’t normal and <UnderlinedText offset={0} colorGradient={'#007A95 0%, #0087A8 49.28%, #00677F 100%'}>she deserve better.</UnderlinedText></div>
						<div className={style.tedTalkContainer}>
							<div className={style.title}>Ted talk</div>
							<div className={style.subtitle}>She is Not Heard</div>
							<div className={style.text}>Stigma, Bad experiences with HCPs, lack of treatment options (**emotionally feels out of control)</div>
						</div>
					</div>
				</div>
			</div>
			<div className={style.thirdSection}>
				<div className={style.secondVideo}>
					<video width="100%" controls ref={video2Ref as unknown as (React.LegacyRef<HTMLVideoElement> | undefined)}>
						<source src={SheIsNotHeard} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
				<div className={style.cloudsAndFeatherContainer}>
					<div className={style.cloudsAndFeather}>
						<div className={style.phrase3}>
							<Phrase
								text={'I have never seen a discussion like this on uterine fibroids and I hope that it continues with/for others!'}
								externalStyle={{ width: 1100 }}
							/>
							<Signature
								text={'Kimberly P, Target UF Patient'}
								externalStyle={{ marginTop: 104 }}
								openModal={() => openParticipantsModal(7)}
							/>
						</div>
						<div id="chapter3" className={style.chapterThree}>
							<Chapter
								chapterNumber={'chapter 03'}
								chapterTitle={'Opportunity Space'}
								chapterBackgroundImage={ChapterImage03}
							/>
						</div>
					</div>
				</div>
				<div className={style.millenialMomContainer}>
					<div className={style.millenialMomContainerInternal}>
						<div className={style.text}><UnderlinedText colorGradient={'#FFF0BE 0%, #F2DBB0 100%'}>Whether she’s a millennial mom</UnderlinedText> worried about the future of her fertility or a mature crusader trying to juggle her busy life, she is trying to keep her head above water as she faces her next phase of womanhood.</div>
					</div>
				</div>
				<div className={style.opportunityToBeFreeContainer}>
					<div className={style.opportunityToBeFreeContainerInternal}>
						<div className={style.text}>She can face these chaotic waves head on if she has a treatment option that was created specifically with her in mind. This gives her hope for smooth sailing in the days to come.</div>
						<div className={style.text}><UnderlinedText colorGradient={'#FFF0BE 0%, #F2DBB0 100%'}>The opportunity to be free</UnderlinedText> of the choppy waters and simply enjoy more of the things she love motivates her to speak to her doctor about a new treatment option.</div>
					</div>
				</div>
				<div className={style.thirdVideo}>
					<video width="100%" controls ref={video3Ref as unknown as (React.LegacyRef<HTMLVideoElement> | undefined)}>
						<source src={OpportunityToBeFree} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>
				<div id="patientsGallery" className={style.patientsGallery}>
					<div className={style.patientsGalleryContainer}>
						<div className={style.text}>The Gallery of Our Target UF Patients</div>
						{
							Participants.map((patient, index) => (
								<div key={`patient-image-${patient.name}`} className={style.patientImageContainer} onClick={() => openParticipantsModal(index)}>
									<img src={patient.avatar} />
								</div>
							))
						}
					</div>
				</div>
				<div className={style.cloudsAndThankYouContainer}>
					<div className={style.cloudsAndThankYou}></div>
				</div>
				<div className={style.textContainer}>
					<span className={style.thankYou}>Thank you</span>
				</div>
			</div>
			<Modal
				isOpen={emotionalModalIsOpen}
				onRequestClose={closeEmotionalModal}
				style={customStyles}
			>
				<ImageCarousel images={letters} />
			</Modal>
			<Modal
				isOpen={drawingsModalIsOpen}
				onRequestClose={closeDrawingsModal}
				style={customStyles}
			>
				<ImageCarousel images={sketchs} />
			</Modal>
			{participantsModalIsOpen && (
				<div className={style.patientPage}>
					<div className={style.closeIconContainer} onClick={closeParticipantsModal}>
						<img src={CloseIcon} alt="close" />
					</div>
					<div className={style.paginatorContainer}>
						<div className={style.button} onClick={() => openParticipantsModal(participantToShow.index - 1)}>
							<img src={PreviousIcon} alt="previous" />
							<span>PREVIOUS</span>
						</div>
						<div className={style.button} onClick={() => openParticipantsModal(participantToShow.index + 1)}>
							<span>NEXT</span>
							<img src={NextIcon} alt="next" />
						</div>
					</div>
					<div className={style.side + ' ' + style.side1}>
						<div className={style.profile}>
							<div className={style.avatarContainer}>
								<img src={participantToShow.avatar} alt="avatar" />
							</div>
							<div className={style.titles}>
								<span className={style.name}>{participantToShow.name}</span>
								<span className={style.ethnic}>{participantToShow.ethnic}</span>
								<span className={style.selfDefinition}>{participantToShow.selfDefinition}</span>
							</div>
						</div>
						<div className={style.contentContainer}>
							<div className={style.textContainer}>
								<div className={style.wordsILiveBy}>
									<h1>words I live by</h1>
									<span>{participantToShow.wordsILiveBy}</span>
								</div>
								<div className={style.myRelationshipWithMyHCP}>
									<h1>my relationship with my hcp</h1>
									<span>{participantToShow.myRelationshipWithMyHCP}</span>
								</div>
								<div className={style.whatMyBiographyWouldSay}>
									<h1>WHAT MY BIOGRAPHY WOULD SAY</h1>
									<span>{participantToShow.whatMyBiographyWouldSay}</span>
								</div>
							</div>
							<div className={style.mediaContainer}>
								<div className={style.mediaImageContainer}>
									<img src={participantToShow.pictureThatRepresentsMyself} alt="participant image" />
								</div>
								<span>a song that represents how I feel in a great moment:</span>
								<div className={style.mediaSongContainer}>
									<iframe src={`https://open.spotify.com/embed/track/${participantToShow.songId}?utm_source=generator&theme=0`} width="100%" height="80" frameBorder="0" allowFullScreen={true} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
								</div>
							</div>
						</div>
					</div>
					<div className={style.side + ' ' + style.side2}>
						<div className={style.phraseContainer}>
							<span>best ADVICE I’VE EVER RECEIVED:</span>
							<h1>{participantToShow.phrase}</h1>
						</div>
						<div className={style.letterToSelf}>
							<div className={style.title}>{`Dear ${participantToShow.dear}`}</div>
							<div className={style.letterToMy}>
								<div className={style.titleContainer}>to MY PAST SELF</div>
								<span>{participantToShow.pastSelf}</span>
							</div>
							<div className={style.letterToMy}>
								<div className={style.titleContainer}>TO MY FUTURE SELF</div>
								<span>{participantToShow.futureSelf}</span>
							</div>
						</div>
						<div className={style.symptomsContainer}>
							<div className={style.textContainer}>
								<h1>no UF SYMPTOMS FOR ME IS LIKE</h1>
								<span>{participantToShow.symptomsForMe}</span>
							</div>
							<div className={style.mediaContainer}>
								<div className={style.mediaContainerImage}>
									<img src={participantToShow.pictureSymptom} alt="symptom image" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default Welcome;
