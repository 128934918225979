import React from 'react';
import style from './ChapterTitle.module.scss';

interface ChapterTitleProps {
	text: string;
}

const ChapterTitle: React.FC<ChapterTitleProps> = ({ text }) => {
	return (
		<span
			className={style.container}
		>
			{text}
		</span>
	);
};

export default ChapterTitle;
