import AkiaW from '../../../assets/img/participants/Akia W.jpeg';
import AkiaWBiography from '../../../assets/img/participants/biography/Akia W.jpg';
import AkiaWSymptom from '../../../assets/img/participants/symptom/Akia W.png';
import AliciaP from '../../../assets/img/participants/Alicia P.jpeg';
import AliciaPBiography from '../../../assets/img/participants/biography/Alicia P.jpg';
import AliciaPSymptom from '../../../assets/img/participants/symptom/Alicia P.png';
import AmandaM from '../../../assets/img/participants/Amanda M.jpeg';
import AmandaMBiography from '../../../assets/img/participants/biography/Amanda M.jpg';
import AmandaMSymptom from '../../../assets/img/participants/symptom/Amanda M.png';
import AshleyA from '../../../assets/img/participants/Ashley A.png';
import AshleyABiography from '../../../assets/img/participants/biography/Ashley A.jpg';
import AshleyASymptom from '../../../assets/img/participants/symptom/Ashley A.png';
import BetinaG from '../../../assets/img/participants/Betina G.jpeg';
import BetinaGBiography from '../../../assets/img/participants/biography/Betina G.jpg';
import BetinaGSymptom from '../../../assets/img/participants/symptom/Betina G.png';
import BrennaP from '../../../assets/img/participants/Brenna P.jpeg';
import BrennaPBiography from '../../../assets/img/participants/biography/Brenna P.jpg';
import BrennaPSymptom from '../../../assets/img/participants/symptom/Brenna P.png';
import DorisM from '../../../assets/img/participants/Doris M.jpeg';
import DorisMBiography from '../../../assets/img/participants/biography/Doris M.jpg';
import DorisMSymptom from '../../../assets/img/participants/symptom/Doris M.png';
import JenniferE from '../../../assets/img/participants/Jennifer E.png';
import JenniferEBiography from '../../../assets/img/participants/biography/Jennifer E.jpg';
import JenniferESymptom from '../../../assets/img/participants/symptom/Jennifer E.png';
import KaseyM from '../../../assets/img/participants/Kasey M.jpeg';
import KaseyMBiography from '../../../assets/img/participants/biography/Kasey M.jpg';
import KaseyMSymptom from '../../../assets/img/participants/symptom/Kasey M.png';
import KimberlyP from '../../../assets/img/participants/Kimberly P.png';
import KimberlyPBiography from '../../../assets/img/participants/biography/Kimberly P.jpg';
import KimberlyPSymptom from '../../../assets/img/participants/symptom/Kimberly P.png';
import LatanyaB from '../../../assets/img/participants/Latanya B.jpeg';
import LatanyaBBiography from '../../../assets/img/participants/biography/Latanya B.jpg';
import LatanyaBSymptom from '../../../assets/img/participants/symptom/Latanya B.png';
import LeanneS from '../../../assets/img/participants/Leanne S.jpeg';
import LeanneSBiography from '../../../assets/img/participants/biography/Leanne S.jpg';
import LeanneSSymptom from '../../../assets/img/participants/symptom/Leanne S.png';
import LNadineH from '../../../assets/img/participants/L Nadine H.png';
import LNadineHBiography from '../../../assets/img/participants/biography/L Nadine H.jpg';
import LNadineHSymptom from '../../../assets/img/participants/symptom/L Nadine H.png';
import MargaritaV from '../../../assets/img/participants/Margarita V.png';
import MargaritaVBiography from '../../../assets/img/participants/biography/Margarita V.jpg';
import MargaritaVSymptom from '../../../assets/img/participants/symptom/Margarita V.png';
import MichelleF from '../../../assets/img/participants/Michelle F.png';
import MichelleFBiography from '../../../assets/img/participants/biography/Michelle F.jpg';
import MichelleFSymptom from '../../../assets/img/participants/symptom/Michelle F.png';
import NaydalisP from '../../../assets/img/participants/Naydalis P.jpeg';
import NaydalisPBiography from '../../../assets/img/participants/biography/Naydalis P.jpg';
import NaydalisPSymptom from '../../../assets/img/participants/symptom/Naydalis P.png';
import NekedraJ from '../../../assets/img/participants/Nekedra J.png';
import NekedraJBiography from '../../../assets/img/participants/biography/Nekedra J.jpg';
import NekedraJSymptom from '../../../assets/img/participants/symptom/Nekedra J.png';
import PreetiM from '../../../assets/img/participants/Preeti M.png';
import PreetiMBiography from '../../../assets/img/participants/biography/Preeti M.jpg';
import PreetiMSymptom from '../../../assets/img/participants/symptom/Preeti M.png';
import RachelK from '../../../assets/img/participants/Rachel K.jpeg';
import RachelKBiography from '../../../assets/img/participants/biography/Rachel K.jpg';
import RachelKSymptom from '../../../assets/img/participants/symptom/Rachel K.png';
import RaynaT from '../../../assets/img/participants/Rayna T.jpeg';
import RaynaTBiography from '../../../assets/img/participants/biography/Rayna T.jpg';
import RaynaTSymptom from '../../../assets/img/participants/symptom/Rayna T.png';
import RosalindR from '../../../assets/img/participants/Rosalind R.png';
import RosalindRBiography from '../../../assets/img/participants/biography/Rosalind R.jpg';
import RosalindRSymptom from '../../../assets/img/participants/symptom/Rosalind R.png';
import SabrinaJ from '../../../assets/img/participants/Sabrina J.jpeg';
import SabrinaJBiography from '../../../assets/img/participants/biography/Sabrina J.jpg';
import SabrinaJSymptom from '../../../assets/img/participants/symptom/Sabrina J.png';
import SamanthaM from '../../../assets/img/participants/Samantha M.png';
import SamanthaMBiography from '../../../assets/img/participants/biography/Samantha M.jpg';
import SamanthaMSymptom from '../../../assets/img/participants/symptom/Samantha M.png';
import SarahS from '../../../assets/img/participants/Sarah S.jpeg';
import SarahSBiography from '../../../assets/img/participants/biography/Sarah S.jpg';
import SarahSSymptom from '../../../assets/img/participants/symptom/Sarah S.png';
import ScherrieP from '../../../assets/img/participants/Scherrie P.jpeg';
import ScherriePBiography from '../../../assets/img/participants/biography/Scherrie P.jpg';
import ScherriePSymptom from '../../../assets/img/participants/symptom/Scherrie P.png';
import StephanieW from '../../../assets/img/participants/Stephanie W.jpeg';
import StephanieWBiography from '../../../assets/img/participants/biography/Stephanie W.jpg';
import StephanieWSymptom from '../../../assets/img/participants/symptom/Stephanie W.png';
import YvetteL from '../../../assets/img/participants/Yvette L.png';
import YvetteLBiography from '../../../assets/img/participants/biography/Yvette L.jpg';
import YvetteLSymptom from '../../../assets/img/participants/symptom/Yvette L.png';

export interface Participant {
	index: number;
	avatar: string;
	name: string;
	ethnic: string;
	selfDefinition: string;
	wordsILiveBy: string;
	myRelationshipWithMyHCP: string;
	whatMyBiographyWouldSay: string;
	pictureThatRepresentsMyself: string;
	songId: string;
	phrase: string;
	dear: string;
	pastSelf: string;
	futureSelf: string;
	symptomsForMe: string;
	pictureSymptom: string;
}

const Participants = [
	{
		index: 0,
		avatar: AliciaP,
		name: 'Alicia P.',
		ethnic: 'african american',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Either accept what you can’t change or change what you cannot accept.”',
		myRelationshipWithMyHCP: 'If my doctor came to live with me, they would see constantly living my life to the fullest. I would want them to know everything about my Uterine Fibroids because they sometimes only hear what we tell them and have tunnel vision. I think they would most be surprised by my attitude and how I find ways to cope and live and I think they would probably expect me to be worst off than I actually am.',
		whatMyBiographyWouldSay: '“Anyone with a chronic condition or pain knows how overwhelming that can be at times. We sometimes carry the weight of the world in trying to live and manage our symptoms. But, we keep moving forward no matter what.”',
		pictureThatRepresentsMyself: AliciaPBiography,
		songId: '05wIrZSwuaVWhcv5FfqeH0',
		phrase: '“You need to open up a bit more”',
		dear: 'Alicia',
		pastSelf: '“It is okay to not be ok. It is okay to not do everything. It is okay to do this on your terms.”',
		futureSelf: '“Know what makes you feel better.”',
		symptomsForMe: '“It’s jambalaya pasta. I am from New orleans and this is one of my favorite dishes. it is comforting. It is me. It makes me feel comforted. Makes me feel like me.”',
		pictureSymptom: AliciaPSymptom,
	},
	{
		index: 1,
		avatar: LeanneS,
		name: 'Leanne S.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Positive mind, positive life"',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see Not much daily pain . I would want them to know that my scars are biggest issue about my Uterine Fibroids because they cause pain and insecurity. I think they would most be surprised by how little I notice them until my period and I think they would probably expect that I\'d be more affected by them.”',
		whatMyBiographyWouldSay: '“I feel like this image shows a young girl thinking a lot about something troubling, but also shows some serene peaceful background because it\'s both. "A girls journey with fibroids" I would talk about my scary situation at a young age, not knowing what was wrong, to wake up after what was supposed to be day surgery to find out they had removed a large mass from my uterus. Then to go on with a normal life for years only to have it happen all over again down the road. The moral of the story would be to pay attention to your body and be honest with your doctors about pain or discomfort. Advocate for yourself most importantly.”',
		pictureThatRepresentsMyself: LeanneSBiography,
		songId: '5N4erncE7kuUccm7zEmwzk',
		phrase: '“Holding on to anger about anything. About a person, about a situation, about anything, is like holding on to a hot coal and expecting somebody else to be burned.”',
		dear: 'Leanne',
		pastSelf: '“Don’t be afraid. Not every day will be filled with pain in your body that not everyone understands. There will be so many normal days. Take care of your health. Ask for information to understand what’s happening. Don’t blindly agree with whatever a Dr. says. It’s okay to be anxious. It’s okay to be upset.”',
		futureSelf: '“There is a chance your body will heal from the scars and you could have a completely normal childbirth/pregnancy experience. Even if you don’t - there are so many ways to have a family. Don’t stress about the ‘What-ifs’. Keep educating yourself.”',
		symptomsForMe: '“The taste of slush makes me think of sunshine and warm weather. I think happy thoughts! It makes me think of days when I am not dealing with anxiety or pain. It reminds me of good days where I can wear shorts and don’t have pain or bleeding.”',
		pictureSymptom: LeanneSSymptom,
	},
	{
		index: 2,
		avatar: MargaritaV,
		name: 'Margarita V.',
		ethnic: 'HISPANIC',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“It is my decision whether I will have a good day or not.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see how often the pain and bloating happens. I would want them to know the huge need for more research about my Uterine Fibroids because there has to be something that is not surgical removal to alleviate some of the pain and discomfort associated with the fluctuant size of these fibroids. I think they would most be surprised by how tough I am and how much pain I can take before I resort to going to see them and I think they would probably expect a lot more visits from me so that they can look for more ways to help.”',
		whatMyBiographyWouldSay: '“The title would be ‘meet my bumpy uterus’. I feel like if this was a biography, I would have so much more than this experience to write about, but for the sake of this exercise I would talk about everything from the minute I found out, to my questions, to my doubts. I would talk about how frightened I was and how I learned to cope with the monthly issues accompanied by this. The moral of the story at the end would be: ‘hang in there and reach out, you\'re not alone.”',
		pictureThatRepresentsMyself: MargaritaVBiography,
		songId: '22oEJW6r2rMb9z4IntfyEa',
		phrase: '“It doesn\'t matter because they aren\'t going to remember the times you weren\'t there or how many hours you put them in daycare, they aren\'t going to remember much of that because they will be occupied with other things. What they are going to remember is that one hour you spent with them.”',
		dear: 'Margarita',
		pastSelf: '“You’re basically a rollercoaster of emotions but I am here to tell you that everything will be ok. Take a deep breath and know that you’re not alone. There are many many many women going through the same thing. Look for forums, for chats, and for any friends that may be going through the same thing for support. Hang in there.”',
		futureSelf: '“I hope in the future they come up with new non-invasive therapies for the treatment of fibroids to help millions of women who deal with this on a semi-daily basis.”',
		symptomsForMe: '“Just like I love the smell and taste of strawberry shortcake, I love the symptom free days of having uterine fibroids. Last time I had this was a couple days ago at the Strawberry Festival. Let\'s just say it was magical! They have a build your own station where you can but endless amount of whipped cream and strawberry sauce on that deliciously warm biscuit. Just like enjoyed that occasion, it is absolutely freeing to have symptom-free days.”',
		pictureSymptom: MargaritaVSymptom,
	},
	{
		index: 3,
		avatar: KaseyM,
		name: 'Kasey M.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“What is the worst thing that could happen?”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see I learned to overcome it and work with it. I would want them to know that sometime their lack of bedside manner effects people when they don’t sympathize with their patients. about my Uterine Fibroids because they don’t personally have it. I think they would most be surprised by how much I actually do bleed and how many tampons and pads I actually do go through let me and I think they would probably expect a far lot less.”',
		whatMyBiographyWouldSay: '“I would call my book “The Color Red”. I often times think of red when I think of my period but I also think of red when I think of pain and anger. I think too it would draw people in since it’s not flat out open with what it’s about. I’d talk about how I found out about it, the horror stories of having them and talk about what helped me when I had to suffer with them. I think bringing more conversation to the subject would help eliminate the taboo around them.”',
		pictureThatRepresentsMyself: KaseyMBiography,
		songId: '1etiUDkISHELzQGMY79ryt',
		phrase: '“Send it to everybody”',
		dear: 'Kasey',
		pastSelf: '“As you grow older you will soon develop something called Uterine fibroids. You’ll learn that you are not alone and you’ll find many of your friends have them as well. You’ll all support each other and be there for each other. They are not fun but you are tougher than they are.”',
		futureSelf: '“You made it! While it was hard you managed to make it through. You learned a lot while having UF but you also helped others learn about it too. Thank you for taking care of yourself and making it a priority.”',
		symptomsForMe: '“We don’t get these too often and when we do it’s a treat. It’s also a fun light appetizer that doesn’t make me feel full. When I don’t have a flair up of my UF, I don’t feel full or bloated. I also compare the two because we don’t go there often and it’s a treat when we do, it’s the same for UF. It’s not often I don’t have a flair up when on my period, so when it does happen, it’s a treat.”',
		pictureSymptom: KaseyMSymptom,
	},
	{
		index: 4,
		avatar: YvetteL,
		name: 'Yvette L.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '"The way to get started is quit talking and start doing".',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see how often I have to rinate. I would want them to know everything about my Uterine Fibroids because they impact every part of my life. I think they would most be surprised by how often I have to change my pad and I think they would probably expect me having to do this less at my age.”',
		whatMyBiographyWouldSay: '“This image is a good representation of my biography because it shows that there has been a great journey, but the destination hasn\'t been met yet. The title of my book would be The Ever changing Journey. My biography would talk about things that occurred leading up to my diagnosis, how I dealt with my diagnosis, my treatment, my prognosis and the future. What I would want readers to walk away knowing is that you may have a difficult road ahead, but it can be manageable if you seek out help and resources.”',
		pictureThatRepresentsMyself: YvetteLBiography,
		songId: '60nZcImufyMA1MKQY3dcCH',
		phrase: '“Life is short, you have to live your life, you can\'t just go around tip toeing and trying to be extra cautious and not living."',
		dear: 'Yvette',
		pastSelf: '“You will experience some scary things and you won’t know why this happened to you. You will be okay. You will need to be strong, courageous, and adaptable. Seek medical attention and talk to others. Having a support system is important.”',
		futureSelf: '“You are like a chameleon, you can adapt to anything. You know how to do research to find if there are any new developments. If there are questions, you know who to call. You have survived living with fibroids and conquered the beast.”',
		symptomsForMe: '“A peach is a great representation of a day with no symptoms of UF because they are sweet and good for you. I enjoy eating peaches and they represent summer for me. Whenever I taste a peach I feel happy and hum a little. When I am symptom free, I’m so happy because I can enjoy my life. I sing and dance a lot more when I’m symptom free.”',
		pictureSymptom: YvetteLSymptom,
	},
	{
		index: 5,
		avatar: JenniferE,
		name: 'Jennifer E.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“For I know the plans that I have for you. Plans to prosper you, not to harm you. But plans to give you hope and a future.”',
		myRelationshipWithMyHCP: '‘If my doctor came to live with me, they would see that I go to the bathroom a lot because I have bladder pressure, but not really urinating a lot. I would want them to know how I feel about about my Uterine Fibroids because I\'ve been thinking about their impact on my health lately.”',
		whatMyBiographyWouldSay: '“My story is one of overcoming, victory. The story would be called Overcoming. My biography would talk about dating / relationships / desiring motherhood / challenges to motherhood / learning about IVF / dealing with fibroids and all the changes and depression / overcoming. The end of the book - I want them to know that fibroids doesn\'t have to be so life altering. You can still go after what you want in life, it might just look differently and you have to be open. Also knowledge is power, and understanding things better initially leads to better outcomes.”',
		pictureThatRepresentsMyself: JenniferEBiography,
		songId: '4vgTAdLit4VNnxZ7YK06NW',
		phrase: '“Make good choices”',
		dear: 'Jennifer',
		pastSelf: '“I want you to know that many women get fibroids. Many black women at that. Don’t be scared, but take care of them. If you wait around, you will live life uncomfortably and they could impact your ability to have children. It’s important to try not to stress. You only have one temple -take care of it”',
		futureSelf: '“Do you remember when you were dealing with all of the craziness with the fibroids? You overcame all of that! You made it to the other side. Take care of yourself so we can minimize them coming back. You are amazing.”',
		symptomsForMe: '“Coffee - I love it. It\'s balanced. I think this comes to mind because coffee has a few ingredients (like your day) but it all comes together, works out, and is good. This image makes me feel relaxed & chill. I feen for the taste when I go a long time without. I think when my uterine fibroids aren\'t acting up, I\'m free to be in my element.”',
		pictureSymptom: JenniferESymptom,
	},
	{
		index: 6,
		avatar: BetinaG,
		name: 'Betina G.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Failure is NOT an option and the timing is NOW.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see a clean home and a closet with a box full of pads and feminine products . I would want them to know I\'m not happy about my Uterine Fibroids because they affect me negatively almost daily due to pain. I think they would most be surprised by my progress and journey and I think they would probably expect my to schedule a treatment soon.',
		whatMyBiographyWouldSay: '“My biography would be called Saving your Uterus. It would talk about taking control of your body, mind and health, fibroid treatment illustrations, information, symptoms, treatment options. At the end of the book I would like the the reader to feel encouraged, well informed, hopeful, empowered and some relief.”',
		pictureThatRepresentsMyself: BetinaGBiography,
		songId: '1Fyltzo3uQmYwLNIw5Lt4c',
		phrase: '“To care about everything, to believe in myself and to set boundaries”',
		dear: 'Betina',
		pastSelf: '“You should have taken better care of yourself. You’re a good person always caring and giving to others. Why don’t you take care of yourself?”',
		futureSelf: '“Keep up the good work. Continue to take care of your mind, body, and spirit. You are safe, well, and you are important. You matter. Seize the moment.”',
		symptomsForMe: '“Harrah Buffet in Las Vegas - the food was great, such a great variety, quality, and everything was perfect. This place was amazing, I look forward to going back one day, it was my ‘Happy Place’! When my fibroids aren’t acting up I have the patience to crack lobster, peel crawfish, sit in a restaurant and go through buffet lines.”',
		pictureSymptom: BetinaGSymptom,
	},
	{
		index: 7,
		avatar: KimberlyP,
		name: 'Kimberly P.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Be who you are and say what you feel because those who mind don’t matter and those who matter don’t mind.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see The homeopathic methods I use to treat my symptoms . I would want them to know of the back and leg cramps about my Uterine Fibroids because they can be debilitating . I think they would most be surprised by by methods I use to self soothe and I think they would probably expect them to recommend other treatments.”',
		whatMyBiographyWouldSay: '“This image summaries the feelings I have when dealing with uterine fibroids since it’s a silent condition to have. The title would be called Emotional Rollercoaster. The book would be discussing how I was diagnosed and my feelings of being in early 40s without children and the emotional toll I go through. I would want readers to have more understanding of treatment options, symptoms to look for and what to talk to your doctor about.”',
		pictureThatRepresentsMyself: KimberlyPBiography,
		songId: '7aBxcRw77817BrkdPChAGY',
		phrase: '“This image summaries the feelings I have when dealing with uterine fibroids since it’s a silent condition to have. The title would be called Emotional Rollercoaster. The book would be discussing how I was diagnosed and my feelings of being in early 40s without children and the emotional toll I go through. I would want readers to have more understanding of treatment options, symptoms to look for and what to talk to your doctor about.”',
		dear: 'Kimberly',
		pastSelf: '“Listen to your body more. The weird periods you are getting are not normal. You should not be in that much pain and bleed so heavily. Take better care of yourself.”',
		futureSelf: '“While you may be fearful of missing motherhood, there is still a chance. Don’t give up hope. You are stronger than you think and can get through a lot.”',
		symptomsForMe: '“This is a good representation of a day without symptoms because for me the condition is so layered into my feelings about my health and vitality and the symptoms are so different each time. Each time I eat this item I enjoy it. When I don’t have symptoms it concerns me because I get concerned I am headed to menopause.”',
		pictureSymptom: KimberlyPSymptom,
	},
	{
		index: 8,
		avatar: MichelleF,
		name: 'Michelle F.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“It is better to be prepared and have no opportunity, than to have an opportunity and not be prepared.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see the size of my UFs and be able to monitor whether or not it is growing, remaining the same, or shrinkin.  I would want them to know how it affects my period and about my Uterine Fibroids because I want them to be able to figure out how to heal me. I think they would most be surprised by how much my own behaviors have helped to limit its effects or impact on my daily life and I think they would probably expect that the effects would be worse than their expectations.”',
		whatMyBiographyWouldSay: '“My biography would talk about first being diagnosed, the pain I used to have (one time it was so bad I had to go to the hospital), the knowledge I obtained on how to be healthier and take better care of myself, and how I overcame.”',
		pictureThatRepresentsMyself: MichelleFBiography,
		songId: '60nZcImufyMA1MKQY3dcCH',
		phrase: '“To ask for what I want”',
		dear: 'Michelle',
		pastSelf: '“It is not your fault that you have UF. Know you are better than this and will get through these tough times and overcome. Stay focused and strong. You will win.”“It is not your fault that you have UF. Know you are better than this and will get through these tough times and overcome. Stay focused and strong. You will win.”',
		futureSelf: '“While you should have never had to go through what you went through, the main thing to focus on is that the nightmare is finally over and you are free to enjoy your life and live the life you want.”',
		symptomsForMe: '“The taste and flavor of these foods is a great representation of not only tasting good, but happiness and a strong mind, which is what I have on symptom-free days (no harassments).”',
		pictureSymptom: MichelleFSymptom,
	},
	{
		index: 9,
		avatar: AkiaW,
		name: 'Akia W.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“When they go low, You go High” Michelle Obama',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see I am a happy person. I would want them to know that the pain is severe and I make a big deal about my Uterine Fibroids because it alters my life and I miss opportunities because of it . I think they would most be surprised by the active approach I take to learning more about it and I think they would probably expect me to ask what is up and coming that I would be a good fit for.”',
		whatMyBiographyWouldSay: '“It shows all of the emotions I have had through this process. It would be called Unapologetically Me. I would talk about when I found out, how I learned to research, and how I was able to be a strong advocate for my case. The moral would be to stay the course no matter what and it would be okay.”',
		pictureThatRepresentsMyself: AkiaWBiography,
		songId: '1uXbwHHfgsXcUKfSZw5ZJ0',
		phrase: '“I was told to make sure I market what I offered but make them pay for my services”',
		dear: 'Akia',
		pastSelf: '“You woke up uncertain of what is going on, but know that this will pass as other things have.”',
		futureSelf: '“You will be amazed at how much you have surpassed. One day soon, you will not have to worry as much because help is on the way”',
		symptomsForMe: '“The flavor is sweet and sour and it is how I am because I do not have to worry about Fibroids. It is a sour taste because of the feeling in which I will be feeling and this image makes me feel happy and colorful.”',
		pictureSymptom: AkiaWSymptom,
	},
	{
		index: 10,
		avatar: LNadineH,
		name: 'L. Nadine H.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Just keep going no matter what.” Reginald Lewis',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see that I care about my body. I would want them to know that I take an active approach about my Uterine Fibroids because I want to make sure it does not get out of control. I think they would most be surprised by number or pads I use and I think they would probably expect to be that way under the circumstances.”',
		whatMyBiographyWouldSay: '“I would tell the to look to the road ahead. This image a good representation because you may have a long journey and I want you to be patient with yourself. Is it representing you personal journey. The title of your biography be called, "A Look to the Road Ahead". The book would talk about seeking information and finding the right care. The moral of the story, don\'t be afraid and to care yourself. I want readers to walk away knowing that it\'s okay and it\'s all about you.”',
		pictureThatRepresentsMyself: LNadineHBiography,
		songId: '4vgTAdLit4VNnxZ7YK06NW',
		phrase: '“Never let anyone steal your joy”',
		dear: 'Nadine',
		pastSelf: '“The unknown can be scary.”',
		futureSelf: '“Now you know what is going on. Stay calm, you can do this.”',
		symptomsForMe: '“It\'s a peaceful moment for me. It\'s a moment that allows me to focus on other things. It\'s a moment that puts a smile on my face. This taste comes to mind when thinking about my experience with uterine fibroids, because it is a feel good moment when everything is alright.”',
		pictureSymptom: LNadineHSymptom,
	},
	{
		index: 11,
		avatar: DorisM,
		name: 'Doris M.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“What goes around comes around.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see the pain I deal with, with my fibroids. I would want them to know everything about my Uterine Fibroids because They may be able to prescribe something to help other than birth control. I think they would most be surprised by the fact that I double up on pads and tampons because of the heavy bleeding and I think they would probably expect the pain to debilitate me at times.”',
		whatMyBiographyWouldSay: '“If I were to write a biography I would choose this photo for the cover and name it “Being Eve”. In my opinion it would be a good representation because everyone knows who Eve is from the biblical verse of Adam and Eve. My biography would talk about all the trials and tribulations of being a woman. The good, the bad, and the ugly. The moral of the story would be to show the strength of women. How wonderful and powerful we are. How we persevere. How amazing we are. The readers would walk away knowing this.”',
		pictureThatRepresentsMyself: DorisMBiography,
		songId: '60nZcImufyMA1MKQY3dcCH',
		phrase: '“Our life is our responsibility, no one else\'s”',
		dear: 'Doris',
		pastSelf: '“Don’t give up. You have a bright future ahead of you! You will face something heartbreaking, please don’t let this make you give up on your dream. Always keep your head high and when times get rough, remember, this too shall pass.”',
		futureSelf: '“Just wanted to let you know how proud I am of you and how far you’ve come. You’ve never let up and I hope you never will. I’m sure you’re going to go through more tough times. Always remind yourself that tough times don’t last, tough people do.”',
		symptomsForMe: '“I chose Baked Ziti because it’s my favorite dish and my days without symptoms are my favorite days! Ziti is my comfort food and I feel like my most comfortable self when I’m not experiencing any symptoms of uterine fibroids. I can always eat ziti when I’m not in pain or bloated and I never feel disgusting or drained after.”',
		pictureSymptom: DorisMSymptom,
	},
	{
		index: 12,
		avatar: NaydalisP,
		name: 'Naydalis P.',
		ethnic: 'HISPANIC',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Do onto others as you wish them to do to you.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see my physical and emotional discomfort. I would want them to know that I experience pain , pass blood clots and bleed a lot about my Uterine Fibroids because I would like to create a good plan and explore all of my options. I think they would most be surprised by my uterine fibroids growing to the point you could feel them when I was pregnant and I think they would probably expect to try to come up with better options to treat the uterine fibroids.”',
		whatMyBiographyWouldSay: '“The side with the rain represents how I feel when I have symptoms and the side with the sunshine represents how I feel when my uterine fibroids are not interfering with my daily life. The title might be ‘A day in my life living with uterine fibroids.’ The moral of the story would be that there are ups and downs that you experience uterine fibroids. This is manifested with physical symptoms and emotional discomfort.”',
		pictureThatRepresentsMyself: NaydalisPBiography,
		songId: '7npLlaPu9Mfno8hjk5OagD',
		phrase: '“Go on Tinder for dating”',
		dear: 'Naydalis',
		pastSelf: '“Please do not assume the pain and discomfort you feel is normal. The sooner you find out about the UF, the sooner you can make the necessary changes. Do not push yourself. Allow yourself to rest. Speak to your doctor and join a women’s group for emotional support. Do not try to be brave. Listen to your body.”',
		futureSelf: '“You have been through so much. Continue to look for alternative ways to deal with your fibroids. Do your own research. Don’t give up. Things will get better. Stay strong.”',
		symptomsForMe: '“The taste of this strawberry shortcake is very fresh and light. It’s not overly decadent, but has enough sweetness to satisfy the craving. It’s balanced and delicious and never overwhelming. It represents a day with no uterine fibroid symptoms because on those days I feel most at ease and most balanced. This is similar to a day without my uterine fibroids acting up because it feels like a daily reward.”',
		pictureSymptom: NaydalisPSymptom,
	},
	{
		index: 13,
		avatar: SamanthaM,
		name: 'Samantha M.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“I just tell myself to stop and enjoy the moment or if I\'m stressed I usually just tell myself to take deep breaths and count in and out.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see frustration. I would want them to know As much as possible about my Uterine Fibroids because it seems like there\'s still alot questions. I think they would most be surprised by pain that can come with it and I think they would probably expect I\'m not really sure.”',
		whatMyBiographyWouldSay: '“Title: My love and hate relationship with Uterine Fibroids. Topics I\'d talk about: My life before fibroids, with fibroids, and without after treatment (hopefully one day). Moral: That you can live with fibroids even though you may not want to on most days. You will survive.”',
		pictureThatRepresentsMyself: SamanthaMBiography,
		songId: '2nU4VND7sfHwzweYzCSMoI',
		phrase: '“Not to care what people think of you don\'t let it affect you”',
		dear: 'Samantha',
		pastSelf: '“Stop being so judgy. You’ll have tough times but always appreciate the people there for you”',
		futureSelf: '“Things could always be worse. They aren’t cancerous, just a pain. You got this.”',
		symptomsForMe: '“Gooey butter cake. Its incredible and melts in your mouth. Just pure glory *hungry *bummed *pleased *just happiness always a huge plus not to be in physical pain”',
		pictureSymptom: SamanthaMSymptom,
	},
	{
		index: 14,
		avatar: SarahS,
		name: 'Sarah S.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Today I will not stress over things I can\'t control.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see I\'m normal most of the month, except for that 1 week. I would want them to know The inconvenience about my Uterine Fibroids because It does disrupt my life. I think they would most be surprised by How often I have to change my feminine products and I think they would probably expect It to be less annoying than it is.”',
		whatMyBiographyWouldSay: '“The title would be ‘You\'re Not Funny Period. A tale of a woman and her Uterine Fibroids.’ I would tell funny stories about my experience. Things that at the time we\'re embarrassing, but can find some amusement in them now. Also, the conversations I had with doctors before diagnosis, and what I was really thinking in my head at the time. The moral of the story is that it can be tough and frustrating, but you can get through it, and it helps to find the humor in it.”',
		pictureThatRepresentsMyself: SarahSBiography,
		songId: '59BjW5qGX4NwRy9aWAkV46',
		phrase: '“To trust my gut and also that not everyone is going to like you and that\'s okay because you\'re not going to like everyone else and that friendships come and go but people are in your life for a reason.”',
		dear: 'Sarah',
		pastSelf: '“I know you’re wondering what is going on. I know you’re looking for answers and just a heads up, there aren’t any you will like. Stay strong and don’t give into peer pressure. While it’s annoying, you are strong and will manage to carry on with your fun and adventures. Don’t be afraid to ask questions. You got this!”',
		futureSelf: '“I hope you’ve discovered a miraculous cure or at least some groundbreaking / life changing products! If not, you’ve still got this! Remember, it’s crappy for a day or two and then life returns to normal. You’re still fortunate in so many ways.”',
		symptomsForMe: '“This is my favorite meal. It is warm and comforting, like a day without any symptoms. I think this comes to mind because it is a meal that makes me happy and carefree… because if I\'m eating this I\'m not caring about calories at the moment. When I don\'t have symptoms I\'m carefree also. When I eat this I get all warm and pretty much have a foodgasm. That\'s what it\'s like when I\'m symptom free, it\'s the best feeling ever.”',
		pictureSymptom: SarahSSymptom,
	},
	{
		index: 15,
		avatar: AmandaM,
		name: 'Amanda M.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Risk -vs- Reward I would consider myself a bit of a risk taker.”',
		myRelationshipWithMyHCP: '‘If my doctor came to live with me, they would see how large the blood clots are. I would want them to know how much of my life is spent in pain or discomfort about my Uterine Fibroids because I think they should educate their patients on their options. I think they would most be surprised by The amount of blood and I think they would probably expect That I would be tired from the period.”',
		whatMyBiographyWouldSay: '“My fibroids don’t really define me as a person, but they do define my uterine health. I think good a good biography would be straight forward and give accurate information. The title would be Living with Fibroids. I would talk about your body as a whole. And the importance of one organ that men do not have… your uterus! I would give detailed information about what life was like with fibroids. And what it was like before them! I want people to learn that you can live with fibroids, but you have choices for treatment!”',
		pictureThatRepresentsMyself: AmandaMBiography,
		songId: '0m7TguDgLue2LCaDF7B4qx',
		phrase: '“Worry is just wasteful energy”',
		dear: 'Amanda',
		pastSelf: '“Take care of your body, you only get one!”',
		futureSelf: '“You became brave and had enough and took care of your body. I’m so proud of you!”',
		symptomsForMe: '“This is a good representation of a day with no fibroid pain because it is fresh and light and yummy! I would typically eat this at a party, maybe in the spring or summer. I had this at a bay shower last summer. I was happy and carefree and not in pain so that’s how it would be similar to a day without fibroids.”',
		pictureSymptom: AmandaMSymptom,
	},
	{
		index: 16,
		avatar: BrennaP,
		name: 'Brenna P.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '"You gotta keep on keepin\' on.”',
		myRelationshipWithMyHCP: '‘If my doctor came to live with me, they would see How much pain I ignore. I would want them to know how bad my symptoms can be, and how frequent about my Uterine Fibroids because you can only explain so much. I think they would most be surprised by how I manage my days and I think they would probably expect me to complain more.”',
		whatMyBiographyWouldSay: '“I chose an image of a scale because it\'s not the easiest thing to balance your daily life with fibroids “Keeping My Balance: Life with Uterine Fibroids"I would talk about everything. My pregnancy experiences, and how having kids has affected my fibroids. My symptoms, and how I manage them. I would want readers to understand that it is possible to have a fulfilling life with fibroids. It is possible to balance fibroids with an everyday life.”',
		pictureThatRepresentsMyself: BrennaPBiography,
		songId: '3kUMnDLAQPnDmiUMzThrjC',
		phrase: '“This worksheet called ‘fair fighting rules’, and I know it\'s not technically advice but that worksheet has made a huge difference for me and my husband, it\'s just really a set of rules to help you communicate better.”',
		dear: 'Brenna',
		pastSelf: '“That pain is NOT normal. You shouldn’t be in that much agony. Trust your gut. Don’t take no for an answer. Don’t be afraid to speak up and say that something is wrong. There is no shame in talking about it. You are not weak for getting help for this. It only makes you stronger.”',
		futureSelf: '“I hope you never gave up on yourself. I hope you pushed yourself to feel better. I hope you are still the amazingly strong mother you are now.”',
		symptomsForMe: '“Fruit is so flavorful and juicy, and it brings me joy. I feel better when I eat better. I feel proud of myself for choosing a healthy option. I love days when we are playing outside with the kids, sometimes we bring a fruit plate. When my fibroids are not acting up, it’s amazing because I can run around and play with the kids. The fruit makes me think of those happy days.”',
		pictureSymptom: BrennaPSymptom,
	},
	{
		index: 17,
		avatar: PreetiM,
		name: 'Preeti M.',
		ethnic: 'ASIAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Life is about the journey, not the destination."',
		myRelationshipWithMyHCP: '‘If my doctor came to live with me, they would see me in severe pain and with heavy bleeding. I would want them to know that not everyone experiences the same symptoms truly and my symptoms and issues are different about my Uterine Fibroids because they need to be able to inform their other patients that everyone is different. I think they would most be surprised by the severity of my symptoms and I think they would probably expect the symptoms and pain to be less and not as painful.”',
		whatMyBiographyWouldSay: '“I like this image a lot. It shows that you can still enjoy life with the fibroids. That on the inside and outside you are the same person. You can love yourself no matter what is on the inside. Title: My love and hate relationship with Uterine Fibroids. Topics I\'d talk about: My life before fibroids, with fibroids, and without after treatment (hopefully one day). Moral: That you can live with fibroids even though you may not want to on most days. You will survive.”',
		pictureThatRepresentsMyself: PreetiMBiography,
		songId: '5R9a4t5t5O0IsznsrKPVro',
		phrase: '“Persist, be honest about everything, and to basically work hard”',
		dear: 'Preeti',
		pastSelf: '“You are about to experience something that is going to be really hard to understand at first. You are going to research and research until your eyes cross, but you will get through it with the support of your family and friends. Just remember - you got this. You will survive.”',
		futureSelf: '“I want you to know you do survive. You get through it. Everything will be okay. You took it one day at a time. You Made it!”',
		symptomsForMe: '“The taste of this brownie represents my no symptom days because when you eat a sugary treat you feel good, relaxed, and happy. I feel the same way when I have no pain, etc. This brownie makes me feel like I deserve to have one and be happy about it. It makes me feel lucky and excited.”',
		pictureSymptom: PreetiMSymptom,
	},
	{
		index: 18,
		avatar: AshleyA,
		name: 'Ashley A.',
		ethnic: 'CAUCASIAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Get busy living or get busy dying.”',
		myRelationshipWithMyHCP: '‘If my doctor came to live with me, they would see that I am busy and on the go. I would want them to know that the UF really mess with my on the go life about my Uterine Fibroids because they put medown and out when they flair up. I think they would most be surprised by the way they put me down and I think they would probably expect things to be just like they see.”',
		whatMyBiographyWouldSay: '“I chose this because it is a whirlwind. I feel like things are unpredictable and such a whirlwind. The title would be "The Whirlwind of UF". It would talk about the symptoms and feeling of having UF. The moral of the story would be that they are painful but livable!”',
		pictureThatRepresentsMyself: AshleyABiography,
		songId: '36ISlLb12gKuCCVTYXC0gb',
		phrase: '“To be myself”',
		dear: 'Ashley',
		pastSelf: '“There are going to be days where nothing can take the pain away. I want you to know that it will be okay and you will get through it. Live each day as if it were your last!”',
		futureSelf: '“Please be mindful of your body and rest when it needs you to. You are going to miss some of your kids events and time with them, don’t feel bad about it. Make the time you get with them even better and more special”',
		symptomsForMe: '“The flavor comes to mind because I am able to customize it and add onions on one layer or peppers on another. I feel like it relates to the UF because there are different symptoms that go along with it. ”',
		pictureSymptom: AshleyASymptom,
	},
	{
		index: 19,
		avatar: RachelK,
		name: 'Rachel K.',
		ethnic: 'AA; CAUCASIAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Love life to the fullest.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see My fibroid doesn’t always affect my daily life and activities. I would want them to know that I do have off days about my Uterine Fibroids because It’s important that my dr. believes me. I think they would most be surprised by The fact that I handle it so well and I think they would probably expect me to take more medications for it.”',
		whatMyBiographyWouldSay: '“This would be my cover and a good representation, because the woman is sitting there concerned and in deep thought, While her partner or other person looks to be pondering in questioning and a light way why. This represents the thought and attention UF gets for me and for others, like my partner trying to understand why it’s such a impactful thing. I would title the book, “Beyond Fibroids”, to capture all the aspects and experiences of having the condition beyond just wet lays as a thing in the body. Hopefully not to confuse it with beyond, meaning after fibroids, but more so just the branching effect that fibroids have and how we as women deal with the effects well beyond what’s happening just in our uterus. My biography would talk about how to the uterus is so untouchable, these effects, symptoms and experiences from fibroids are so real and not untouchable factors. These are tough conversations but they need to be discussed. At the end of my book, I would want readers to know or take away that no matter what your experience is with fibroids, it’s normal and you will find ways to work around it or get through it.”',
		pictureThatRepresentsMyself: RachelKBiography,
		songId: '0YAQnYt2oB8OdoaeqnHyTI',
		phrase: '“Love life to the fullest”',
		dear: 'Rachel',
		pastSelf: '“Staying calm makes a world of difference. It’s normal to worry, but think about everything that’s on your side. You can get through dealing with the symptoms, know that you are not alone and many people go through this.”',
		futureSelf: '“You know what this is and you know you can conquer it. There may be great days and not so great days. Again, stay calm and level headed. Be open and talk SELF to friends and family about what’s going on, you’ll be surprised that many can relate.”',
		symptomsForMe: '“This represents a fresh fruit salad. The fruit is cut so nicely and displayed greatly and that’s how I feel when I have no symptoms.  I feel that I am at my best and representing myself at my best to others. Similar to my fibroids, not every day am I able to have this experience, but when I do I cherish it and recognize all the greatness that a day without symptoms provides.”',
		pictureSymptom: RachelKSymptom,
	},
	{
		index: 20,
		avatar: LatanyaB,
		name: 'Latanya B.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“You can do whatever you put your mind to”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see me frequently in the bathroom. I would want them to know I do go as much as I say I do. about my Uterine Fibroids because the fibroids are continuously pushing down on my uterus. I think they would most be surprised by how frequent I go and I think they would probably expect less.”',
		whatMyBiographyWouldSay: '“This is mostly how I\'ve been affected from fibroids. It has taken all my energy away and left me very fatigue all the time! "Give Me Energy" would be the title of my biography. I would tell all about how I\'m always tired, unmotivated to do anything, how I cramp and bleed during my cycle, and how my stomach has grown to look as if I\'m pregnant! Then I would tell how it all changed because I had the myomectomy surgery done! And now I love to go out and enjoy my friends! The moral of this story would be "that the grass is greener on the other side"! I want them to walk away knowing what I went through and how I overcame it!!”',
		pictureThatRepresentsMyself: LatanyaBBiography,
		songId: '0nSrUpDjFouhaQB7dOi0h7',
		phrase: '“The grass is not always greener on the other side.”',
		dear: 'Latanya',
		pastSelf: '“I want to warn you about what you’re about to deal with. You will have to go to the bathroom a lot, be fatigued often, have discomfort during sex, and stomach pain. I don’t wish this on anyone.”',
		futureSelf: '“Your life will get better. Once you get over your fears of surgery and get your fibroids removed you will be fine!”',
		symptomsForMe: '“Longhorn Steakhouse outlaw steak is a ‘happy’ meal for me. This is the best steak I have ever had. It is tender, juicy, and melts in your mouth. Eating too much red meat increases the risk of fibroids, so I try not to eat it as much.”',
		pictureSymptom: LatanyaBSymptom,
	},
	{
		index: 21,
		avatar: StephanieW,
		name: 'Stephanie W.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Live simply. Laugh often. Love deeply.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see I am an upbeat and positive person. I would want them to know the size and number about my Uterine Fibroids because they would see I manage pretty well. I think they would most be surprised by how I carry on my activities and I think they would probably expect me to be moving much slower and grumpier.”',
		whatMyBiographyWouldSay: '“I would want my book cover to true story of walking in my shoes... "You Fell Down, But Got Up Gracefully" The road on my journey with fibroids has had its ups and downs but I have learned a lot of life lessons that teach you through trials and tribulations you can get back up again and live life.”',
		pictureThatRepresentsMyself: StephanieWBiography,
		songId: '33vzOPcd9FRirYGlCu32x4',
		phrase: '“Always act like a lady in every situation.”',
		dear: 'Stephanie',
		pastSelf: '“You disrupted my life. Right when I was finding my groove, you swooped in and took over my body. At the time, I could not fathom, but now I see you were teaching me the facts of life. You have taught me some valuable lessons”',
		futureSelf: '“What a journey this has been. I hold all your secrets close to my vest. I know how fragile you are and in pain, so I know what mindset to be in when I am around you.”',
		symptomsForMe: '“The Adams Peanut Butter Cheesecake from the Cheesecake Factory gives me life! Peanut butter is my favorite food - it’s orgasmic for me.”',
		pictureSymptom: StephanieWSymptom,
	},
	{
		index: 22,
		avatar: ScherrieP,
		name: 'Scherrie P.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'millennial mom',
		wordsILiveBy: '“Remember there are 3 types of people in your life: those who helped you in difficult times, those who left you in difficult times, and those who put you in difficult times.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see your average healthy woman. I would want them to know the intricacies about my Uterine Fibroids because they affect me mentally as well as physically. I think they would most be surprised by how much they affect my day to day living and I think they would probably expect the fibroids to be a minor inconvenience.”',
		whatMyBiographyWouldSay: '“I would title it "Living a Double Life with Fibroids." Throughout the book it would describe how I manage to go through life appearing normal, while hiding these demons. The moral of the story would be that everybody has their own battle they are fighting and nobody\'s life is perfect. So don\'t ever feel that you have to live up to perfection.”',
		pictureThatRepresentsMyself: ScherriePBiography,
		songId: '4bHsxqR3GMrXTxEPLuK5ue',
		phrase: '“Always follow your first reaction.”',
		dear: 'Scherrie',
		pastSelf: '“I overlooked many opportunities to improve the quality of my health and body. I strongly recommend that you start to invest heavily in the quality of your body and health. Don’t be confined to feel you have to rely solely on a doctor\'s advice. Look for help and guidance from many places in many forms.”',
		futureSelf: '“Life will find a way. You have found yours. I would love to know what you have learned along your way. I hope you took the time to follow up with friends on facebook. I encourage you to be more consistent with the holistic treatments. Stick with it.”',
		symptomsForMe: '“Vanilla represents purity and cleanliness. No mess and no drama. Starting off new with a clean slate. When I go without it I crave and yearn for it.The last time I tasted this was last month a couple of days after my period. The taste was so invigorating and inspiring because it allowed me to have a clean mindset and make good on my embetterment plans.”',
		pictureSymptom: ScherriePSymptom,
	},
	{
		index: 23,
		avatar: SabrinaJ,
		name: 'Sabrina J.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Their story is not your story.”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see my anxiety every time certain symptoms appeared. I would want them to know all the details about my Uterine Fibroids because I want them to understand that it\'s not just these things in my uterus but that it has an effect on other parts of my life. I think they would most be surprised by my mental state when it comes to thinking about them and I think they would probably expect me to be okay with my current situation.”',
		whatMyBiographyWouldSay: '“This is an image of me and my son. It would be my cover for two reasons: 1. Fibroids affect Black Women at an alarming rate. So therefore I want to target this audience. SO I want to personalize it with a picture of myself. 2. I want to include my son because he was a bright light in all of the darkness that came with fibroids. To have birthed him during my bouts with fibroids is a blessing. The title of my bio is "They Didn\'t Tell You?". It talks about my journey through fibroids and how is wish I took the time to get more education. But it also a book of encouragement to let women know that they are not alone and that they have options and they should explore their options.”',
		pictureThatRepresentsMyself: SabrinaJBiography,
		songId: '6OxTGWA4bxZ1u1auaeTDNv',
		phrase: '“I remember my dad telling me when I got upset because the girls didn’t like me or not and my dad was like baby girl everybody ain’t gonna like you you got to get used to that”',
		dear: 'Sabrina',
		pastSelf: '“Girl we’ve been through a lot especially when it comes to these monsters in our uterus. I want to apologize for not seeking help sooner and understanding that periods were not supposed to last that long or be that painful. However, we have endured and will still endure.”',
		futureSelf: '“You are stronger than you know. I hope your health is great and that these little monsters haven’t stood in the way of SELF living life or attaining your goals.”',
		symptomsForMe: '“I LOVE the taste of a good Martini!! so when I have one I feel refreshed relaxed. This image make me feel blissful. Like I want to be on a beach somewhere or out with my friends having a great time. Once when I had a few lemon martinis i felt a sense of euphoria because the flavor was refreshing and I was with my friends at one of my favorite hangouts. It just like when my UF are not acting up I feel normal and carefree.”',
		pictureSymptom: SabrinaJSymptom,
	},
	{
		index: 24,
		avatar: RosalindR,
		name: 'Rosalind R.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: '“Just move! Don’t find up. To stay strong and to not let anyone hinder you!”',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see my misery. I would want them to know how extremely painful about my Uterine Fibroids because I suffer a lot. I think they would most be surprised by ways to meditate and I think they would probably expect Me to get impatient.”',
		whatMyBiographyWouldSay: '“I’m waiting for this miserable situation to END! This image gives me a small sense of hope. I would tile this, ‘The End’! I would talk about my uterine fibroid experience from the beginning to the end! The message would be, to decide on a solution and to not continue to suffer!”',
		pictureThatRepresentsMyself: RosalindRBiography,
		songId: '7y0sJmJv3ZtDeeHnPlCHEe',
		phrase: '“When you get up out of bed every day, think about what good you\'re going to do in the world. Think about trying to make others feel good about themselves and just trying to do better.”',
		dear: 'Rosalind',
		pastSelf: '“I wish that I had known about Fibroid embolization. And had been more aggressive about getting more information about that procedure!”',
		futureSelf: '“I need to look into other options and to possibly choose other doctors.”',
		symptomsForMe: '“I simply love chocolate cake. Because, it reminds me of great times. I feel warm and cozy. I am miserable, when I haven’t had it. I would feel so alive! I was having a great time at a friends birthday party. And no Fibroid discomfort! It was awesome!”',
		pictureSymptom: RosalindRSymptom,
	},
	{
		index: 25,
		avatar: RaynaT,
		name: 'Rayna T.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: 'Trust in the Lord with all your heart, and lean not on your own understanding. Proverbs 3:5',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see how drained I am during my time of the month. I would want them to know the severity about my Uterine Fibroids because it is often so difficult to cope with. I think they would most be surprised by the efforts I make to try to ignore the pain and I think they would probably expect that it isn\'t as bad as I say it is.”',
		whatMyBiographyWouldSay: '“If I wrote a biography about my experiences with uterine fibroids, it would be about all the up\'s and down\'s. The constant highs and lows of the roller-coaster are a direct comparison to the highs and lows of uterine fibroids. The title would be "Uterine Fibroids: The Highs and Lows."   The biography would talk about my life prior to being diagnosed, the pain I felt before being diagnosed, and the changes in my life after being diagnosed. At the end of the story, the moral would be that there are a bunch of highs and lows when dealing with this condition but you can choose to either enjoy the ride or be miserable on the ride.',
		pictureThatRepresentsMyself: RaynaTBiography,
		songId: '6IH3XfSDOaVknLHhsIvyuX',
		phrase: '“My mouth produces my prosperity and so that\'s just saying that whatever I speak will come into existence.”',
		dear: 'Rayna',
		pastSelf: '“You are so much stronger than you think you are. The pain that are feeling is not normal and you have every right to request every test possible to see what could be wrong. Advocate for yourself! When your fibroids are giving you the toughest times, just rest. You don\'t always have to be wonder woman…. You\'ve got this girl! I know it\'s painful and very irritating, but you\'ll make it through.”',
		futureSelf: '“You are going to be so much more knowledgeable about your uterine fibroids! You are going to have them so under control that you forget they are even there… You\'ve found a support group to help uplift you and you never gave up on hope. You rock and did the absolute best you could do. Keep looking for answers and trying new things.”',
		symptomsForMe: '“No symptom days of UF feels like a brownie sundae from Baskin Robbins. I think this taste and flavor comes to mind when I\'m thinking about my experience because it\'s my favorite and signifies a food that makes me very relaxed which is the opposite of how I feel with UF. When my fibroids aren\'t acting up, I\'m not 100% as thrilled as I am eating a brownie sundae but they are pretty close. I am so happy and satisfied that I\'m not experiencing pain in that moment that leaves me tired, irritable, and in pain.”',
		pictureSymptom: RaynaTSymptom,
	},
	{
		index: 26,
		avatar: NekedraJ,
		name: 'Nekedra J.',
		ethnic: 'AFRICAN AMERICAN',
		selfDefinition: 'mature crusader',
		wordsILiveBy: 'Love the way you live.',
		myRelationshipWithMyHCP: '“If my doctor came to live with me, they would see me engaging in my normal activities. I would want them to know I\'m in a healthy mental state about my Uterine Fibroids because I have to be. I think they would most be surprised by the lack of interference in my life and I think they would probably expect Pain and suffering.”',
		whatMyBiographyWouldSay: '“This image is a good representation because no matter what obstacles I may encounter in this life, I will do my best to reign supreme. The Title of my biography would be ‘Keep the Faith.’ It would talk how although I haven\'t had a perfect life, I did try to make the best of every situation, both good and bad. I would want readers to walk away knowing if you have a positive attitude, a will to succeed, and a great support system, you can overcome most things in life. The rest will work itself out.”',
		pictureThatRepresentsMyself: NekedraJBiography,
		songId: '7p8l7HiPoz90dg423iURKW',
		phrase: '“What you put up with is what you end up with. People will do what you allow.”',
		dear: 'Nekedra',
		pastSelf: '“Be proactive about your health. Listen to your body. Don\'t assume because you\'re young that you\'re in good health and nothing is wrong… When things seem tough, prayer works. It will bring you comfort and peace.”',
		futureSelf: '“Make time for yourself each day. If you don\'t take care of #1 no one else will… It\'s ok to be afraid, have anxiety, uncertainty, and to be angry. You\'re not perfect, no one is. We all have flaws. It\'s what makes each of us unique.”',
		symptomsForMe: '“Cookies and cream ice cream is a good representation of a day without fibroid symptoms. This flavor comes to mind because it\'s my favorite dessert. I enjoy tasting this dessert often. I feel a sense of pleasure and fulfillment whenever I eat this dessert. It\'s the same way I feel when I don\'t have to deal with fibroid symptoms.”',
		pictureSymptom: NekedraJSymptom,
	},
];

export default Participants;
