import React from 'react';
import style from './Phrase.module.scss';

interface PhraseProps {
	text: string;
	fontSize?: number;
	quoteWidth?: number;
	quoteHeight?: number;
	lineHeight?: number;
	externalStyle?: React.CSSProperties;
}

const Phrase: React.FC<PhraseProps> = ({ text, fontSize, lineHeight, externalStyle, quoteWidth, quoteHeight }) => {
	const baseQuoteWidth = 55;
	const baseQuoteHeight = 44;
	const beforeAndAfterStyle = {
		width: `${quoteWidth || baseQuoteWidth}px`,
		height: `${quoteHeight || baseQuoteHeight}px`,
		backgroundSize: `${quoteWidth || baseQuoteWidth}px ${quoteHeight || baseQuoteHeight}px`,
	};

	return (
		<div className={style.container} style={externalStyle}>
			<span className={style.phrase} style={{ fontSize: fontSize || 45, lineHeight: `${lineHeight || 73}px` }}>
				<span className={style.openQuote} style={{...beforeAndAfterStyle}}></span>
				{text}
				<span className={style.closeQuote} style={{...beforeAndAfterStyle}}></span>
			</span>
		</div>
	);
};

export default Phrase;
