import React from 'react';
import style from './ChapterNumber.module.scss';

interface ChapterNumberProps {
	text: string;
}

const ChapterNumber: React.FC<ChapterNumberProps> = ({ text }) => {
	return (
		<span
			className={style.container}
		>
			{text}
		</span>
	);
};

export default ChapterNumber;
