import React from 'react';
import style from './TurnCard.module.scss';

interface TurnCardProps {
	middleWord: string;
	leftWord: string;
	rightWord: string;
	leftText: string;
	rightText: string;
	leftColorGradient: string;
	rightColorGradient: string;
	leftImage: string;
	rightImage: string;
}

const TurnCard: React.FC<TurnCardProps> = ({ middleWord, leftWord, rightWord, leftText, rightText, leftColorGradient, rightColorGradient, leftImage, rightImage }) => {
	const [isHoverLeftCard, setIsHoverLeftCard] = React.useState(false);
	const [isHoverRightCard, setIsHoverRightCard] = React.useState(false);

	return (
		<div className={style.container}>
			<div className={style.middleWord}>{middleWord}</div>
			<div
				className={style.card}
				style={{
					backgroundImage: isHoverLeftCard ? `url(${leftImage})` : `linear-gradient(${leftColorGradient})`,
				}}
				onMouseOver={() => setIsHoverLeftCard(true)}
				onMouseOut={() => setIsHoverLeftCard(false)}
			>
				<span className={style.text}>{isHoverLeftCard && leftText}</span>
				<span className={`${style.word} ${isHoverLeftCard ? style.coloredWord : ''}`}>{leftWord}</span>
			</div>
			<div
				className={style.card}
				style={{
					backgroundImage: isHoverRightCard ? `url(${rightImage})` : `linear-gradient(${rightColorGradient})`,
				}}
				onMouseOver={() => setIsHoverRightCard(true)}
				onMouseOut={() => setIsHoverRightCard(false)}
			>
				<span className={style.text}>{isHoverRightCard && rightText}</span>
				<span className={`${style.word} ${isHoverRightCard ? style.coloredWord : ''}`}>{rightWord}</span>
			</div>
		</div>
	);
};

export default TurnCard;
