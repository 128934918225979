/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import './ImageCarousel.scss';
import { Carousel } from 'react-carousel-minimal';
import React from 'react';

const ImageCarousel = ({ images }) => {
	return (
		<Carousel
			data={images}
			time={2000}
			width='850px'
			height='500px'
			dots={true}
			slideBackgroundColor='transparent'
			slideImageFit='contain'
			thumbnails={true}
			radius='10px'
			style={{
				textAlign: 'center',
				maxWidth: '850px',
				maxHeight: '500px',
				margin: '40px auto',
			}}
		/>
	);
};

export default ImageCarousel;
