import { AuthUpdateContext } from '../../contexts/AuthContext';
import Menu from '../../components/Menu/Menu';
import React from 'react';
import style from './Login.module.scss';

const Login: React.FC = () => {
	const { setIsAuth } = React.useContext(AuthUpdateContext);
	const [keyword, setKeyword] = React.useState('');
	const [error, setError] = React.useState('');

	const checkKeyword = React.useCallback((): void => {
		if (keyword === 'UFZMET') {
			setError('');
			setIsAuth(true);
		} else {
			setError('Keyphrase is incorrect. Please review and try again.');
		}
	}, [keyword, setIsAuth]);

	React.useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();
				checkKeyword();
			}
		};

		document.addEventListener('keydown', listener);

		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, [checkKeyword]);

	return (
		<div className={style.container}>
			<div className={style.menuContainer}>
				<Menu hideOptions={true} />
			</div>

			<div className={style.formContainer}>
				<span className={style.introduction}>This website is for internal, educational purposes only</span>
				<span className={style.message}>Please enter your keyphrase to access the report</span>
				<div className={style.textFieldContainer}>
					<input className={style.textField} type={'text'} value={keyword} placeholder={'Type Your Keyphrase Here'} onChange={(event) => setKeyword(event.target.value)} />
					<span className={style.errorMessage}>{error}</span>
				</div>
				<input className={style.button} type={'button'} onClick={checkKeyword} value={'Submit'} />
			</div>
		</div>
	);
};

export default Login;
