import Arrow from '../../assets/img/arrow_open_text.svg';
import React from 'react';
import style from './OpenText.module.scss';

interface OpenTextProps {
	title: string;
	data: JSX.Element;
}

const OpenText: React.FC<OpenTextProps> = ({ title, data }) => {
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<div className={style.container}>
			<div className={style.row} onClick={(): void => setIsOpen(!isOpen)}>
				<div className={style.rowLeftSide}>
					<span className={style.title}>{title}</span>
				</div>
				<div className={style.rowRightSide}>
					<div className={`${style.arrowContainer} ${isOpen ? style.open : ''}`}>
						<img src={Arrow} alt="arrow" />
					</div>
				</div>
			</div>
			<div className={`${style.data} ${isOpen && style.open}`}>
				{data}
			</div>
		</div>
	);
};

export default OpenText;
